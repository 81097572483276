/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import PlanDetails from '../../grid-v2/PlanDetails';
import NTXCardBadges from '../sub-components/NTXCardBadges';
import NTXPlanDetails from '../../grid-v2/NTXPlanDetails';
import CardDetailsTrigger from '../../CardDetailsTrigger';

import close from '../../../svg/close.svg';
import useBreakpoints from '../../../js/hooks/useBreakPoints';
import tracking from '../../../js/project/tagular/tracking';

function NTXCardV2({
  plan,
  site,
  disableCartLinks,
  onOrderOnline,
}) {
  const { name } = plan;
  const [detailsOpen, setDetailsOpen] = useState(false);
  const { price } = plan.rates.find((rate) => rate.type === 'EnergyCharge');

  const planTermText = plan.termText.replace(/Months/g, 'mos.').replace(/Month to Month/g, 'Monthly');
  const planPricePerUnit = price ? (price * 100).toFixed(2) : '0';
  const showCartCTA = plan.cartActive && !disableCartLinks && !plan?.supplier?.controls?.disableCart;
  const showPhoneCTA = plan?.supplier?.phone?.length > 0;
  const supplierPhone = plan.supplier.phone;

  const ctaURL = plan.cartLink;
  const planType = plan.type.toUpperCase();
  const unitOfMeasure = Object.keys(plan.unitOfMeasure).length ? plan.unitOfMeasure.description : plan.unitOfMeasure;

  const { isSm, isXl } = useBreakpoints();

  // Ensure all phone numbers are formatted like 1-888-888-8888
  // Assumes all phone numbers will come back either as 888-888-8888 or 1-888-888-8888
  const formatSupplierPhone = (num) => {
    const firstTwoNumbers = num.substring(0, 2);

    if (firstTwoNumbers !== '1-') {
      const newSupplierNumber = `1-${num}`;
      return newSupplierNumber;
    }
    return num;
  };

  // Filter out supplier and plan badges
  const supplierBadges = plan.badges.filter((badge) => {
    if (badge.name.includes('gas') || badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const planBadgesFiltered = plan.badges.filter((badge) => {
    if (!badge.name.includes('gas') && !badge.name.includes('electric')) {
      return badge;
    }
    return null;
  });

  const hasBadges = !!plan.badges.length;

  const closeDetails = () => {
    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'More Details'
      },
      actionOutcome: 'Plan details closed'
    });

    setDetailsOpen(false);
    document.body.classList.remove('grid-v2__mobile-locked');
  };

  const handleOpenDetails = (detailsOpened) => {
    setDetailsOpen(detailsOpened);

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'More Details'
      },
      actionOutcome: 'Plan details expanded'
    });

    // Lock mobile viewport to prevent background from scrolling
    if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');
  };


  let planUnit = unitOfMeasure?.toUpperCase();
  if (planUnit?.includes('THM')) planUnit = 'THERM';
  if (planUnit?.length === 0 || planUnit === null) planUnit = 'KWH';

  return (
    <article className="plan-card-v2 ntx">
      <div className="plan-card-v2__top-split ntx">
        <div className="plan-card-v2__top-medium ntx">
          <div className="plan-card-v2__top-section ntx">
            <div className="plan-card-v2__top-container ntx">
              <div className="plan-card-v2__title-container ntx">
                {/* Supplier Badge */}
                <div>
                  <NTXCardBadges badges={hasBadges ? supplierBadges : null} featuredCard={false} supplierBadges />
                </div>
                <div>
                  {' '}
                  {/* Plan Info */}
                  {/* Logo */}
                  <div className="plan-card-v2__logo--ntx">
                    <img className="plan-card-v2__logo" id="ntx-plan-card-logo-img" src={plan.supplier.logo} alt={plan.supplier.name} />
                  </div>
                  {/* Title */}
                  <div className="plan-card-v2__title">{name}</div>
                </div>
              </div>
              <div className="plan-card-v2__price-badge-container ntx">
                {/* Price per Unit & Term Length */}
                <div className="plan-card-v2__price-container ntx">
                  <div className="plan-card-v2__price-term-label">
                    <p className="plan-card-v2__price-label ntx">Price per</p>
                    <p className="plan-card-v2__price-label-secondary ntx">{`${planUnit}`}</p>
                    <strong className="plan-card-v2__price-value ntx">{`${planPricePerUnit}¢`}</strong>
                  </div>
                  <div className="plan-card-v2__price-term-label">
                    <p className="plan-card-v2__price-label ntx">Term length</p>
                    <p className="plan-card-v2__price-label-secondary ntx">{`${planType} RATE`}</p>
                    <strong className="plan-card-v2__price-value ntx">{planTermText}</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* CTAs Small Breakpoint */}
          <div className="plan-card-v2__cta-container">
            <div className="plan-card-v2__cta-container-inner">
              <a
                className={`plan-card-v2__cta plan-card-v2__cta--cart ${!showPhoneCTA && 'ntx-center-cartCTA'}`}
                style={(!showCartCTA) ? { display: 'none' } : {}}
                href={ctaURL}
                id="ntx-cart-cta"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                onClick={(e) => onOrderOnline(e, ctaURL, plan)}
              >
                      Check availability
              </a>
              <button
                type="submit"
                className="grid-button grid-button--ntx button--phone u-display-flex@lg u-justify-content-center@lg"
                style={(!showPhoneCTA) ? { visibility: 'hidden' } : {}}
              >
                <a
                  className={`plan-card-v2__cta phone--with-icon ${!showCartCTA && 'ntx-center-fuse'}`}
                  style={(!showPhoneCTA) ? { visibility: 'hidden' } : {}}
                  id="ntx-phone-cta"
                  href={`tel:${formatSupplierPhone(supplierPhone)}`}
                >
                  <span className="ntx-phone-number-cta">{formatSupplierPhone(supplierPhone)}</span>
                </a>
              </button>

            </div>
          </div>
          {/* Plan Badges */}
          <div className="ntx-plan-badge-container">
            <NTXCardBadges badges={planBadgesFiltered} featuredCard={false} planType={planType} />
          </div>
          {/* Details trigger */}
          {isSm && (
            <div className="card__section--card-details-trigger-ntx" id="card-details-trigger-ntx">
              <div className="u-display-flex u-align-items-center u-justify-content-space-between">
                <button
                  className={`button--details u-display-flex u-align-items-center ${detailsOpen ? 'button--open' : ''}`}
                  id="ntx-plan-details-button"
                  type="submit"
                  onClick={handleOpenDetails}
                >
                  <span>More details</span>
                </button>
                {/* Details container */}
                {detailsOpen && (
                  // eslint-disable-next-line max-len
                  <div className="featured-plan-card-v2__details featured-plan-cards-v2__details-vertical">
                    <div className="featured-plan-card-v2__details-header ntx">
                      {/* eslint-disable-next-line */}
                      <img
                        className="featured-plan-card-v2__details-close"
                        src={close}
                        alt="Close"
                        onClick={closeDetails}
                      />
                      <div className="featured-plan-card-v2__details-title">{plan.name}</div>
                      <div className="featured-plan-card-v2__details-subtitle">{plan.supplier.name}</div>
                    </div>
                    <div className="featured-plan-card-v2__details-content">
                      <NTXPlanDetails
                        plan={plan}
                        site={site}
                        featured={false}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {!isSm && (
            <CardDetailsTrigger
              plan={plan}
              site={site}
              termText={planTermText}
              isNTX
            />
          )}
        </div>
        {/* Desktop Breakpoint */}
        {isXl && (
          <>
            <div className="plan-card-v2__top-large ntx">
              <div className="plan-card-v2__top-section ntx">
                <div className="plan-card-v2__title-container ntx">
                  {/* Supplier Badge */}
                  <div>
                    <NTXCardBadges badges={hasBadges ? supplierBadges : null} featuredCard={false} supplierBadges />
                  </div>
                  <div>
                    {' '}
                    {/* Plan Info */}
                    {/* Title */}
                    <div className="plan-card-v2__title ntx">{name}</div>
                    {/* Logo */}
                    <div className="plan-card-v2__logo--ntx">
                      <img className="plan-card-v2__logo" id="ntx-plan-card-logo-img" src={plan.supplier.logo} alt={plan.supplier.name} />
                    </div>
                  </div>
                </div>
                <div className="plan-card-v2__price-badge-container ntx">
                  {/* Plan Badges */}
                  <div className="ntx-plan-badge-container">
                    <NTXCardBadges badges={planBadgesFiltered} featuredCard={false} planType={planType} />
                  </div>
                  {/* Price per Unit & Term Length */}
                  <div className="plan-card-v2__price-container ntx">
                    <div className="plan-card-v2__price-term-label">
                      <p className="plan-card-v2__price-label ntx">Price per</p>
                      <p className="plan-card-v2__price-label-secondary ntx">{`${planUnit}`}</p>
                      <strong className="plan-card-v2__price-value ntx">{`${planPricePerUnit}¢`}</strong>
                    </div>
                    <div className="plan-card-v2__price-term-label">
                      <p className="plan-card-v2__price-label ntx">Term length</p>
                      <p className="plan-card-v2__price-label-secondary ntx">{`${planType} RATE`}</p>
                      <strong className="plan-card-v2__price-value ntx">{planTermText}</strong>
                    </div>
                  </div>
                </div>
              </div>
              {/* Details container */}
              <CardDetailsTrigger
                plan={plan}
                site={site}
                termText={planTermText}
                isNTX
              />
            </div>

          </>
        )}

        {/* CTAs Medium Breakpoint */}
        <div className={`plan-card-v2__cta-container-medium ntx ${detailsOpen ? ' plan-card-v2__cta-container-medium--open' : ''}`}>
          <a
            className={`plan-card-v2__cta plan-card-v2__cta--cart ${!showPhoneCTA ? 'ntx-center-cartCTA' : ''}`}
            style={(!showCartCTA) ? { display: 'none' } : {}}
            href={ctaURL}
            id="ntx-cart-cta"
            // eslint-disable-next-line react/jsx-no-target-blank
            target="_blank"
            onClick={(e) => onOrderOnline(e, ctaURL, plan)}
          >
            Check availability
          </a>
          <button type="submit" className="button--phone u-display-flex@lg u-justify-content-center@lg">
            <a
              className={`plan-card-v2__cta phone--with-icon ${!showCartCTA ? 'ntx-center-fuse' : ''}`}
              style={(!showPhoneCTA) ? { visibility: 'hidden' } : {}}
              id="ntx-phone-cta"
              href={`tel:${formatSupplierPhone(supplierPhone)}`}
            >
              <span className="ntx-phone-number-cta">{formatSupplierPhone(supplierPhone)}</span>
            </a>
          </button>
        </div>
      </div>
      {/* Details container */}
      { detailsOpen && (
        <div className="plan-card-v2__details">
          <div className="plan-card-v2__details-header">
            {/* eslint-disable-next-line */}
            <img
              className="plan-card-v2__details-close"
              src={close}
              alt="Close"
              onClick={() => handleOpenDetails()}
            />
            <div className="plan-card-v2__details-title">{name}</div>
            <div className="plan-card-v2__details-subtitle">{plan.supplier.name}</div>
          </div>
          <div className="plan-card-v2__details-content">
            <PlanDetails
              plan={plan}
              site={site}
            />
          </div>
        </div>
      )}
    </article>
  );
}

NTXCardV2.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
};

export default NTXCardV2;
