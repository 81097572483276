/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UsageAmounts, Sites,
} from '@rvpower/constants';
import { usePreamp } from '../../preamp';

import FeaturedCardLabel from '../sub-components/FeatureCardLabel';

import Badge from '../../Badge';
import TxuPricePerKwh from '../../txu-grid/TxuPricePerKwh';
import TxuOverlayContent from '../../txu-grid/OverLayContentTxu';
import phone from '../../../../images/phone.svg';
import useFuseNumber from '../../../js/hooks/useFuseNumber';

const TXURedesignFeaturedCardDefault = ({
  featuredCard,
  onOrderOnline,
  plan,
  planDetailsToggle,
  showPlanDetails,
  site,
  dwellingType
}) => {
  const {
    description, name, type, incentives, badges, cartUrl, usageId
  } = plan;

  const { fuseData } = useFuseNumber();
  const { dataFuseFormat, dataFuseName, permalease } = fuseData;


  /** Incentives:
   * Check if home is a single or multi family to display incentive offer text */
  const incentiveOfferText = dwellingType === 'multi'
    ? incentives.multiFamilyIncentive.offerText
    : incentives.singleFamilyIncentive.offerText;
  const offerIncentivesPreamp = usePreamp('Offer Incentives');
  const TXUBadgesPreamp = usePreamp('Txu badges');

  const incentiveActive = offerIncentivesPreamp && incentiveOfferText !== '';

  const { termText } = plan;

  const isTXU = site === Sites.TXU;
  const editableTxuCartCTAAsset = '[editable] Cart CTA';
  let txuCartCta;
  txuCartCta = usePreamp('Cart CTA') || '';
  txuCartCta = usePreamp('Cart CTA - editable', editableTxuCartCTAAsset);
  const txuWordToUse = txuCartCta || 'Order Online';
  const isTxuPreampWord = isTXU && txuWordToUse;

  const [usage, setUsage] = useState('1000');
  useEffect(() => {
    if (usageId && usageId !== '') {
      setUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  function featuredCardUrl(cartUrlLink, usageAmount) {
    return cartUrlLink.replace(/rateType=[0-9]*/, `rateType=${usageAmount}`);
  }

  return (
    <article className="featured-card txu-redesign" ref={featuredCard}>
      <FeaturedCardLabel plan={plan} />
      <div className="txu-card-details">
        <p className="txu-name">{name}</p>
        <p className="txu-description">{description}</p>
      </div>
      <div className="txu-card-numbers">
        <TxuPricePerKwh
          site={site}
          plan={plan}
          flexRow
          isTXU
          className="card__section-item"
          featuredCard={featuredCard}
        />
        <div className="txu-card-term">
          <span className="overlay__heading--light">
            <strong>Term</strong>
          </span>
          {`${type} rate`}
          {' '}
          <span className="divider" />
          {' '}
          {` ${termText} `}
          {TXUBadgesPreamp && badges?.length ? (
            <Badge type={badges[0].name}>{badges[0].attributeValue}</Badge>
          ) : null}
        </div>
      </div>
      <div className="txu-cta-area">
        <a
          href={`tel:${permalease}`}
          className="txu-number"
          data-fuse
          data-fuse-format={dataFuseFormat}
          data-fuse-name={dataFuseName}
        >
          <img src={phone} alt="phone" />
          <span
            data-fuse
            data-fuse-format={dataFuseFormat}
            data-fuse-name={dataFuseName}
          >
            {permalease}
          </span>
        </a>

        <a
          className="check-availability"
          href={featuredCardUrl(cartUrl, usage, site)}
          onClick={(e) => onOrderOnline(e, featuredCardUrl(cartUrl, usage, site), plan)}
        >
          {isTxuPreampWord}
        </a>

      </div>
      <button
        type="button"
        className="txu-plan-details"
        onClick={(e) => {
          showPlanDetails(e);
        }}
      >
        <u>See Plan Details</u>
      </button>
      <TxuOverlayContent
        site={site}
        plan={plan}
        type={type}
        activeClass={planDetailsToggle}
        incentiveActive={incentiveActive}
        showPlanDetails={showPlanDetails}
      />
    </article>
  );
};

TXURedesignFeaturedCardDefault.propTypes = {
  featuredCard: PropTypes.shape({}).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  dwellingType: PropTypes.string.isRequired
};

export default TXURedesignFeaturedCardDefault;
