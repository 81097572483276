import React, { useRef, useState, useEffect } from 'react';
import { Sites } from '@rvpower/constants';
import { ProductViewed, ProductClicked, ProductPriceViewed } from '@rvpower/track';
import {
  Transition,
  TransitionGroup,
} from 'react-transition-group';
import { sortingFunctions } from '../js/project/mappers';
import { getPlan } from '../js/project/tracking-helper';
import { usePreamp } from './preamp';
import { useMonarch } from './monarch';

// React Components
import TemplateCard from './cards/templates/TemplateCard';
import AtlasCard from './AtlasCard';
import FaqsBanner from './grid-faqs/FaqsBanner';
import VisibleTracker from './VisibleTracker';
import GuaranteeBanner from './GuaranteeBanner';
import Pagination from './pagination/Pagination';
import DisplayPlans from './pagination/DisplayPlans';

function Cards(props) {
  const {
    shouldAbortLoadingPlans,
    plans = [],
    site,
    sort,
    filters,
    comparedPlans,
    setEnableCardAnimation,
    faqsBannerActive,
    disableCartLinks,
    dwellingType,
  } = props;

  const isTxu = site === Sites.TXU;
  const guaranteeBannerBottom = usePreamp('Grid-Guarantee-Banner-Bottom');
  const atlasGridCtaActive = usePreamp('Atlas Grid CTA');
  const pdpTestActivePreamp = usePreamp('Grid Product Detail Page');
  const productsViewed = useRef({});

  const gridPaginationTest = usePreamp('Grid Pagination');
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const { rules } = useMonarch();
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;
  const monarchPDP = rules?.source?.gridProductDetailPage?.value;
  const pdpTestActive = monarchPDP || pdpTestActivePreamp;
  const paginationIncrement = 10;
  const [paginationActive, setPaginationActive] = useState(true);
  const [paginationStartIndex, setPaginationStartIndex] = useState(0);
  const paginationToggle = () => {
    setPaginationActive(!paginationActive);
  };

  useEffect(() => {
    setPaginationStartIndex(0);
  }, [filters]);

  const productViewed = (p) => {
    if (!productsViewed.current[p.productId]) {
      productsViewed.current[p.productId] = true;
      ProductViewed(p);
      ProductPriceViewed(p);
    }
  };

  useEffect(() => {
    setEnableCardAnimation(true);
  }, [comparedPlans]);

  useEffect(() => {
    setEnableCardAnimation(false);
  }, [filters]);

  if (shouldAbortLoadingPlans(plans) === true) {
    return null;
  }

  function customOrder(order = []) {
    if (order.length === 0) {
      return () => -1;
    }

    return (a, b) => {
      if (order.indexOf(a.id) > order.indexOf(b.id)) {
        return 1;
      }
      return -1;
    };
  }

  const sortedPlans = () => {
    if (sort === 'recommended') {
      return coreLogicPreamp || monarchCoreLogic ? plans : plans.sort(customOrder(filters.customPlanOrder));
    }
    return plans.sort(sortingFunctions[sort]);
  };

  const hash = btoa(JSON.stringify({ filters, sort }));

  const paginationRenderCheck = (i) => {
    if (paginationActive && gridPaginationTest
      && (i < paginationStartIndex || i > paginationStartIndex + paginationIncrement - 1)) {
      return false;
    }
    return true;
  };

  return (
    <section className="cards">
      <div className="cards__cards">
        {
          gridPaginationTest && (
          <DisplayPlans
            paginationStartIndex={paginationStartIndex}
            paginationIncrement={paginationIncrement}
            sortedPlans={sortedPlans.length}
            paginationActive={paginationActive}
            paginationToggle={paginationToggle}
          />
          )
        }
        <TransitionGroup
          exit={false}
          className="transition-group__container"
        >
          {sortedPlans().map((plan, i) => {
            if (!paginationRenderCheck(i)) return null;

            const productInfo = getPlan(plan, i + 1, 'FEATURED');
            const pdpProductInfo = { ...productInfo, actionOutcome: 'Entered PDP' };

            const onOrderOnline = (e, cartUrl, planObject) => {
              e.preventDefault();

              const pdpUrl = `${window.location.href}&pdp=true&planId=${plan.id}&utilityId=${plan.supplier.id}`;
              window.localStorage.setItem('planData', JSON.stringify({ ...planObject, cartUrl }));
              ProductClicked(pdpTestActive ? pdpProductInfo : productInfo);

              setTimeout(() => {
                window.location = pdpTestActive ? pdpUrl : cartUrl;
              }, 150);
            };

            return (
              <Transition
                // the key needs to be different on each render so the transition happens on each render
                key={plan.id + hash}
                timeout={500}
                mountOnEnter={false}
              >
                {() => (
                  <>
                    <AtlasCard
                      active={atlasGridCtaActive}
                      currentIndex={i}
                      insertionIndex={1}
                    />
                    <FaqsBanner
                      active={faqsBannerActive}
                      currentIndex={i}
                      insertionIndex={3}
                      position="middle"
                    />
                    {/* eslint-disable-next-line max-len */}
                    <VisibleTracker
                      className="card-tracking__container"
                      id={`${i === 1 ? 'atlas-here' : ''}`}
                      onVisible={() => productViewed(productInfo)}
                    >
                      <TemplateCard
                        plan={plan}
                        planIndex={i}
                        site={site}
                        onOrderOnline={onOrderOnline}
                        disableCartLinks={disableCartLinks}
                        dwellingType={dwellingType}
                      />
                    </VisibleTracker>
                  </>
                )}
              </Transition>
            );
          })}
        </TransitionGroup>
        { isTxu && guaranteeBannerBottom && (
          <GuaranteeBanner location="bottom" />
        )}
        {
          gridPaginationTest && (
            <DisplayPlans
              paginationStartIndex={paginationStartIndex}
              paginationIncrement={paginationIncrement}
              sortedPlans={sortedPlans.length}
              paginationActive={paginationActive}
              paginationToggle={paginationToggle}
              isAtBottom
            />
          )
        }
        {
          gridPaginationTest && (
          <Pagination
            paginationStartIndex={paginationStartIndex}
            setPaginationStartIndex={setPaginationStartIndex}
            paginationIncrement={paginationIncrement}
            sortedPlansLength={sortedPlans.filter((plan) => plan.price).length}
            setPaginationActive={setPaginationActive}
            paginationActive={paginationActive}
            paginationToggle={paginationToggle}
          />
          )
        }
      </div>
    </section>
  );
}

export default Cards;
