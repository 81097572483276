import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

// Icons
import closeIcon from '../../../images/close.svg';

export default function ZipEntryModal(props) {
  const [zipCode, setZipCode] = useState('');
  const [validZipCode, setValidZipCode] = useState(false);
  const [newAddress, setNewAddress] = useState();
  const [anApartment, setAnApartment] = useState();

  const { active, handleModalToggle } = props;

  const closeModal = () => {
    handleModalToggle();
    setValidZipCode(false);
    setZipCode('');
    setNewAddress();
    setAnApartment();
  };

  const submitMoverSwitcher = (e) => {
    e.preventDefault();
    setValidZipCode(true);
  };

  const handleNavigation = (e) => {
    e.preventDefault();
    // Construct the URL based on the state values
    const moveValue = newAddress ? 'movey' : 'moven';
    const apartmentValue = anApartment ? 'apartmenty' : 'apartmentn';
    const currentURL = `${window.location.origin}${window.location.pathname}`;
    window.location.href = `${currentURL}?zipCode=${zipCode}&m=${moveValue}&a=${apartmentValue}`;
  };

  return (
    <Modal
      isOpen={active}
      overlayClassName="grid-modal-overlay zip-entry-overlay"
      className="zip-entry-modal grid-modal-content"
    >
      <button
        onClick={closeModal}
        className="zip-entry-modal__close"
        type="button"
      >
        <img src={closeIcon} alt="close button" />
      </button>

      {validZipCode ? (
        <form className="zip-address-container" onSubmit={handleNavigation}>
          <div className="zip-option-container">
            <p>Are you moving to a new address?</p>
            <div className="zip-radio-container">
              <span onClick={() => setNewAddress(true)}>
                <input
                  type="radio"
                  name="newAddress"
                  id="newAddressYes"
                  checked={newAddress}
                  value={newAddress}
                  required
                />
                {' '}
                <label htmlFor="newAddressYes">Yes</label>
              </span>
              <span onClick={() => setNewAddress(false)}>
                <input
                  type="radio"
                  name="newAddress"
                  id="newAddressNo"
                  checked={newAddress === false}
                  value={newAddress}
                />
                {' '}
                <label htmlFor="newAddressNo">No</label>
              </span>
            </div>
          </div>
          <div className="zip-option-container">
            <p>Are you moving to an apartment?</p>
            <div className="zip-radio-container">
              <span onClick={() => setAnApartment(true)}>
                <input
                  type="radio"
                  name="newAApartment"
                  id="newAApartmentYes"
                  value={anApartment}
                  checked={anApartment}
                  required
                />
                {' '}
                <label htmlFor="newAApartmentYes">Yes</label>
              </span>
              <span onClick={() => setAnApartment(false)}>
                <input
                  type="radio"
                  name="newAApartment"
                  id="newAApartmentNo"
                  checked={anApartment === false}
                  value={anApartment}
                />
                {' '}
                <label htmlFor="newAApartmentNo">No</label>
              </span>
            </div>
          </div>
          <button type="submit">Find Plans</button>
        </form>
      ) : (
        <>
          <p className="zip-entry-modal__headline">
            Enter your ZIP Code to see plans
          </p>
          <form
            className="zip-code-container"
            onSubmit={(e) => submitMoverSwitcher(e)}
          >
            <input
              maxLength="5"
              required
              type="text"
              pattern="[0-9]{5}"
              placeholder="ZIP Code"
              value={zipCode}
              onChange={(e) => setZipCode(e.target.value)}
            />
            <button type="submit">Continue</button>
          </form>
        </>
      )}
    </Modal>
  );
}

ZipEntryModal.propTypes = {
  active: PropTypes.bool.isRequired,
  handleModalToggle: PropTypes.func.isRequired
};
