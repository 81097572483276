import React, { useEffect, useState } from 'react';

import beam from '../../js/project/tagular/beam';

import lightningIcon from '../../../images/lightning-icon.svg';
import closeIcon from '../../../images/close-icon.svg';

const HmcButton = (props) => {
  const {
    modalOpen,
    modalHandler,
    screenWidth,
  } = props;

  const [bannerOpen, setBannerOpen] = useState(true);
  const [bannerAnimate, setBannerAnimate] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setBannerAnimate(true);
    }, 1500);
  }, []);

  const hmcButtonClickHandler = () => {
    const buttonClickedPayload = {
      webContext: {
        sessionId: window?._Cohesion.sessionId,
      },
      webElement: {
        location: 'Grid',
        elementType: 'Button',
        text: 'Help me choose'
      },
      actionOutcome: 'Help me choose opened'
    };
    beam.userTrackingV3('ElementClicked', buttonClickedPayload);

    const hmcStartedPayload = {
      webContext: {
        sessionId: window?._Cohesion.sessionId,
      },
      formContext: {
        formName: 'Help Me Choose',
        formType: 'Questionnaire',
      },
      stepContext: {
        stepName: 'Enter Form',
        stepNumber: 0,
      }
    };
    beam.userTrackingV3('FormStarted', hmcStartedPayload);

    modalHandler(!modalOpen);
  };

  const buttonBannerCloseHandler = () => {
    const bannerClosedPayload = {
      webContext: {
        sessionId: window?._Cohesion.sessionId,
      },
      webElement: {
        location: 'Grid',
      },
      actionOutcome: 'Closed Help Me Choose TT'
    };
    beam.userTrackingV3('ElementClicked', bannerClosedPayload);

    setBannerOpen(false);
  };

  return (
    <div className={`hmc-wrapper-inner hmc-wrapper-inner${screenWidth}`}>
      <div className="hmc-button-container">
        <button
          type="button"
          onClick={hmcButtonClickHandler}
          className="hmc-button"
        >
        Help me choose
        </button>
      </div>
      {bannerOpen && (
      <div className={`hmc-button-banner ${bannerAnimate ? 'hmc-button-banner--active' : ''}`}>
        <div className="hmc-button-banner__inner-left">
          <img
            src={lightningIcon}
            alt="lightning icon"
            className="hmc-button-banner__lightning-icon"
          />
          <div className={`
            hmc-button-banner__text-wrapper
            ${bannerAnimate ? 'hmc-button-banner__text-wrapper--active' : ''}
            `}
          >
            <strong className="hmc-button-banner__title">Need some help deciding?</strong>
            <p className="hmc-button-banner__subtext">Let&rsquo;s find the perfect energy plan for you</p>
          </div>
        </div>
        <div className="hmc-button-banner__inner-right">
          <button
            type="button"
            className="hmc-button-banner__close-button"
            onClick={buttonBannerCloseHandler}
          >
            <img
              src={closeIcon}
              alt="close icon"
              className="hmc-button-banner__close-icon"
            />
          </button>
        </div>
      </div>
      )}
    </div>
  );
};

export default HmcButton;
