/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { Sites, UsageAmounts } from '@rvpower/constants';

export default function TxuPricePerKwh({
  plan,
  site,
  featuredCard
}) {
  const {
    price,
    usage,
    usageId,
    prices,
    unitOfMeasure: { currency, description }
  } = plan;

  const [featuredUsage, setFeaturedUsage] = useState('1000');
  const updatedPrice = prices[featuredUsage];
  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;


  const displayPrice = Number(
    (featuredCard ? updatedPrice : price) * 100
  ).toFixed(1);

  useEffect(() => {
    if (usageId && usageId !== '') {
      setFeaturedUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  return (
    <div className="txu-prices">
      <div className="price__settings">
        <strong className={`uppercase ${isNTX ? 'per-kwh-text--ntx' : ''}`}>
          Price per
          {' '}
          <span>{description || 'kWh'}</span>
        </strong>
        <p>
          {`at ${featuredCard ? featuredUsage : usage} ${description || 'kWh'}`}
        </p>
      </div>
      <span className="price__digits">
        {displayPrice}
        {' '}
        <sup className="price__symbol">{currency || '¢'}</sup>
      </span>
    </div>
  );
}

TxuPricePerKwh.propTypes = {
  site: PropTypes.string.isRequired,
  plan: PropTypes.shape({}).isRequired,
  featuredCard: PropTypes.oneOfType([PropTypes.shape(), PropTypes.bool])
};

TxuPricePerKwh.defaultProps = {
  featuredCard: false
};
