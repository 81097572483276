import React from 'react';

import rating from '../../../images/rating.svg';

export default function TXUGoogleReview() {
  return (
    <p className="rating">
      <img src={rating} alt="rating" />
      <span>
        Rated
        {' '}
        <b>4.7/5</b>
        {' '}
        | Based on 7,000+
        <a
          href={`${'https://www.google.com/maps/place/TXU+Energy+Corporate+Office/@32.'} 
                ${'.8984572,-96.9663083,17z/data=!4m7!3m6!1s0x0:'}
                ${'0xf442d96f72986dfa!8m2!3d32.8984572!4d-96.9641196!9m1!1b1'}
                `}
          rel="noopener noreferrer"
          target="_blank"
        >
            Google Reviews
        </a>
      </span>
    </p>
  );
}
