const Sites = {
  ALLCONNECT: 'allconnect.com',
  CHOOSE: 'chooseenergy.com',
  CTXP: 'choosetexaspower.org',
  NEW_CTXP: 'new.choosetexaspower.org',
  SOE: 'saveonenergy.com',
  TXER: 'texaselectricrates.com',
  NEW_TXER: 'new.texaselectricrates.com',
  TXU: 'shop.txu.com',
  USGE: 'shop.usgande.com',
  ILGE: 'shop.ilgande.com',
  MAGE: 'shop.magande.com',
  MDGE: 'shop.mdgande.com',
  MIGE: 'shop.migande.com',
  NJGE: 'shop.njgande.com',
  OHGE: 'shop.ohgande.com',
  PAGE: 'shop.pagande.com',
  DYNEGY: 'shop.dynegy.com'
};

const defaultGridParams = 'zipCode={{zipCode}}&m={{moverSwitcher}}';
const defaultTxuGridParams = 'zipCode={{zipCode}}&m={{moverSwitcher}}&a={{dwellingType}}';
// eslint-disable-next-line max-len
const defaultCartParams = 'planId={{planId}}&utilityId={{utilityId}}&zipCode={{zipCode}}&rateType={{rateType}}&mover-switcher={{moverSwitcher}}';
const defaultTxuCartParams = '&mover-switcher={{moverSwitcher}}&dwelling-type={{dwellingType}}';
// eslint-disable-next-line max-len
const defaultNewCtxpCartParams = 'planId={{planId}}&utilityId={{utilityId}}&zipCode={{zipCode}}&rateType={{rateType}}&mover-switcher={{moverSwitcher}}';
// eslint-disable-next-line max-len
const defaultCartV3Params = 'planId={{planId}}&utilityId={{utilityId}}&rateType={{rateType}}&mover-switcher={{moverSwitcher}}';

const desktop = 'desktop';
const mobile = 'mobile';

const Urls = {
  [Sites.ALLCONNECT]: {
    cart: `https://www.chooseenergy.com/cart/?${defaultCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms-of-use/'
  },
  [Sites.CHOOSE]: {
    grid: () => '/shop/residential/electricity/TX/{{zipCode}}/?m={{moverSwitcher}}',
    cart: `/cart/?${defaultCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms-of-use/'
  },
  [Sites.CTXP]: {
    grid: () => `/compare-offers/?${defaultGridParams}`,
    cart: `/shop/?${defaultCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms/',
  },
  [Sites.NEW_CTXP]: {
    grid: () => `/compare-offers/?${defaultGridParams}`,
    cart: `/shop/?${defaultNewCtxpCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms/',
  },
  [Sites.SOE]: {
    grid: () => `/plans/?${defaultGridParams}`,
    cart: `/shop/?${defaultCartParams}`,
    privacy: '/privacy/',
    tac: '/terms-of-use/',
  },
  [Sites.TXER]: {
    grid: (isMobile) => `${isMobile ? '/products' : '/compare-offers.php'}?${defaultGridParams}`,
    cart: `/shop/?${defaultCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms-of-use/',
  },
  [Sites.NEW_TXER]: {
    grid: () => `/compare-offers/?${defaultGridParams}`,
    cart: `/shop/?${defaultCartParams}`,
    privacy: '/privacy-policy/',
    tac: '/terms-of-use/',
  },
  [Sites.TXU]: {
    grid: () => `/plans/?${defaultTxuGridParams}`,
    cart: `/cart/?${defaultCartParams}${defaultTxuCartParams}`
  },
};

const SiteCTA = {
  [Sites.CHOOSE]: {
    desktop: 'Check Availability',
    mobile: 'Get Started'
  },
  [Sites.SOE]: {
    desktop: 'Check Availability',
    mobile: 'Continue'
  },
  [Sites.TXER]: {
    desktop: 'Continue',
    mobile: 'Get Started'
  },
  [Sites.NEW_TXER]: {
    desktop: 'Continue',
    mobile: 'Get Started'
  },
  [Sites.CTXP]: {
    desktop: 'Check Availability',
    mobile: 'Continue'
  },
  [Sites.NEW_CTXP]: {
    desktop: 'Check Availability',
    mobile: 'Continue'
  }
};

function siteCta(site, isMobile) {
  const device = isMobile ? mobile : desktop;
  return SiteCTA[site] ? SiteCTA[site][device] : 'Order Online';
}

function siteLegal(site) {
  const siteUrls = Urls[site];
  if (!siteUrls) {
    return ['', ''];
  }
  return [siteUrls.privacy, siteUrls.tac];
}

function replaceParams(url, params) {
  return url.replace(/{{([A-Za-z]+)}}/g, (match, key) => params[key] || '');
}

function gridUrl(site, zipCode, moverSwitcher, dwellingType, isMobile) {
  const siteUrls = Urls[site];

  if (!siteUrls || !siteUrls.grid) {
    return '';
  }

  const params = {
    zipCode, moverSwitcher, dwellingType
  };

  return replaceParams(siteUrls.grid(isMobile), params);
}

function cartUrl(site, planId, utilityId, zipCode, rateType, moverSwitcher, dwellingType) {
  const siteUrls = Urls[site];

  if (!siteUrls || !siteUrls.cart) {
    return '';
  }

  const params = {
    planId, utilityId, zipCode, rateType, moverSwitcher, dwellingType
  };

  return replaceParams(siteUrls.cart, params);
}

function cartV3Url(planId, utilityId, rateType, moverSwitcher) {
  const params = {
    planId, utilityId, rateType, moverSwitcher
  };

  return replaceParams(`/cart/?${defaultCartV3Params}`, params);
}

module.exports = {
  Sites, Urls, gridUrl, cartUrl, cartV3Url, siteCta, siteLegal,
};
