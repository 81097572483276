import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Sites, RatingSites } from '@rvpower/constants';
import { usePreamp } from '../../preamp';

import FeaturedCardSimplified from './FeaturedCardSimplified';
import FeaturedCardV2 from './FeaturedCardV2';
import NTXFeaturedCard from './NTXFeaturedCard';
import BasicFeaturedCard from './BasicFeaturedCard';

/**
 * Higher order component to determine which template card to render
 * @param {*} props
 */
const TemplateFeaturedCard = ({
  ESIID,
  plan,
  onOrderOnline,
  site,
  gridComparisonToolActive,
  comparedPlans,
  setComparedPlans,
  disableCartLinks,
  newGridEnabled = false,
  handleDetailsToggle,
  detailsOpen,
  selected,
  isNTX = false,
  index,
}) => {
  const {
    name,
    supplier,
  } = plan;

  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';
  const { ratings } = supplier;
  const [planDetailsToggle, setPlanDetailsToggle] = useState(false);
  const ratingsPreamp = usePreamp('Star-Ratings');
  const ratingsEnabled = RatingSites.includes(site) ? true : ratingsPreamp;
  const showRatings = ratingsEnabled && ratings;
  const featuredCard = useRef(null);

  /**
   * Shared logic for generic Checkbox component
   */
  const checkbox = {
    checked: comparedPlans.filter((item) => item.name === name).length,
    label: 'CLICK TO COMPARE',
    name,
    value: name
  };

  const addToCompare = () => {
    setComparedPlans([...comparedPlans, plan]);
  };

  const removeFromCompare = (planToRemove) => {
    const newPlans = comparedPlans.filter((thisPlan) => thisPlan.name !== planToRemove);
    setComparedPlans(newPlans);
  };

  const showPlanDetails = (e) => {
    e.preventDefault();
    setPlanDetailsToggle(!planDetailsToggle);
    handleDetailsToggle();
  };
  /**
   * End shared checkbox logic
   */

  switch (site.toLowerCase()) {
    case newGridEnabled && Sites.SOE:
      return (
        <FeaturedCardSimplified
          ESIID={SOEesiid}
          plan={plan}
          site={site}
          onOrderOnline={onOrderOnline}
          handleDetailsToggle={handleDetailsToggle}
          disableCartLinks={disableCartLinks}
          detailsOpen={detailsOpen}
          selected={selected}
          index={index}
        />
      );
    case newGridEnabled && !isNTX && Sites.CHOOSE:
    case newGridEnabled && Sites.NEW_TXER:
    case newGridEnabled && Sites.NEW_CTXP:
      return (
        <FeaturedCardV2
          ESIID={SOEesiid}
          plan={plan}
          site={site}
          onOrderOnline={onOrderOnline}
          handleDetailsToggle={handleDetailsToggle}
          disableCartLinks={disableCartLinks}
          detailsOpen={detailsOpen}
          selected={selected}
          index={index}
        />
      );
    case isNTX && Sites.CHOOSE:
      return (
        <NTXFeaturedCard
          plan={plan}
          handleDetailsToggle={handleDetailsToggle}
          onOrderOnline={onOrderOnline}
          disableCartLinks={disableCartLinks}
          detailsOpen={detailsOpen}
          selected={selected}
          index={index}
          isNTX
        />
      );
    default:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return (
        <BasicFeaturedCard
          ESIID={SOEesiid}
          plan={plan}
          onOrderOnline={onOrderOnline}
          site={site}
          gridComparisonToolActive={gridComparisonToolActive}
          comparedPlans={comparedPlans}
          disableCartLinks={disableCartLinks}
          featuredCard={featuredCard}
          showPlanDetails={showPlanDetails}
          showRatings={showRatings}
          checkbox={checkbox}
          addToCompare={addToCompare}
          removeFromCompare={removeFromCompare}
          planDetailsToggle={planDetailsToggle}
          index={index}
        />
      );
  }
};

TemplateFeaturedCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  gridComparisonToolActive: PropTypes.bool.isRequired,
  comparedPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setComparedPlans: PropTypes.func.isRequired,
  disableCartLinks: PropTypes.bool.isRequired,
  handleDetailsToggle: PropTypes.func.isRequired,
};

export default TemplateFeaturedCard;
