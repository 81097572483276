import React from 'react';
import PropTypes from 'prop-types';

import Providers from '../filters/providers';
import Rating from '../filters/rating';
import Features from '../filters/features';
import RateType from '../filters/rate-type';
import TermLength from '../filters/term-length';
import HomeSize from '../filters/home-size';
import BillPrice from '../filters/bill-price';
import Utility from '../filters/utility';

import StarIcon from './StarIcon';

// configuration object for PillsDropdown
const PillsDropdownObject = ({
  allPlans,
  filters,
  isNTX,
  plans,
  providers,
  rates,
  setFilters,
  setUsage,
  terms,
  site,
  setSelectedUtility,
  utilities,
  utility
}) => ({
  'Home size': {
    component: (
      <HomeSize
        filters={filters}
        setFilters={setFilters}
        setUsage={setUsage}
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: filters.usage > 0 && filters.usage !== 1000,
    number: 1,
    filterType: 'usage',
    trackingName: 'HOME SIZE',
    label: 'Home size',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        usage: 1000
      });
    },
    resetText: 'Clear'
  },
  Utility: {
    component: (
      <Utility
        setSelectedUtility={setSelectedUtility}
        utilities={utilities}
        utility={utility}
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: utility !== null,
    number: 1,
    filterType: 'utilities',
    trackingName: 'UTILITY',
    label: 'Utility',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        utilitiy: ''
      });
    },
    resetText: 'Clear'
  },
  Price: {
    component: (
      <BillPrice
        filters={filters}
        setFilters={setFilters}
        site={site}
      />
    ),
    // number: 0,
    hasIcon: false,
    icon: <></>,
    isActive: (filters?.billPrice?.minBillPrice !== null || filters?.billPrice?.maxBillPrice !== null),
    number: filters?.billPrice?.minBillPrice !== null || filters?.billPrice?.maxBillPrice !== null ? 1 : 0,
    filterType: 'billPrice',
    trackingName: 'MONTHLY BILL PRICE',
    label: 'Price',
    titleText: 'Monthly bill price',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        billPrice: {
          minBillPrice: null,
          maxBillPrice: null
        }
      });
    },
    resetText: 'Clear'
  },
  Features: {
    component: (
      <Features
        filters={filters}
        plans={plans}
        setFilters={setFilters}
        isNTX={isNTX}
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: filters.features.length > 0,
    number: filters.features.length,
    filterType: 'features',
    trackingName: 'FEATURES',
    label: 'Features',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        features: []
      });
    },
    resetText: 'Clear'
  },
  Provider: {
    component: (
      <Providers
        className="grid-v2-providers--pills-dropdown"
        filters={filters}
        isNTX={isNTX}
        plans={plans}
        providers={providers}
        setFilters={setFilters}
        showAll
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: filters.providers.length > 0,
    number: filters.providers.length,
    filterType: 'providers',
    trackingName: 'PROVIDERS',
    label: 'Provider',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        providers: []
      });
    },
    resetText: 'Clear'
  },
  Rating: {
    component: (
      <Rating
        allPlans={allPlans}
        filters={filters}
        setFilters={setFilters}
      />
    ),
    hasIcon: true,
    icon: <StarIcon type="star-pill" />,
    isActive: filters.ratings.length > 0,
    number: filters.ratings.length,
    filterType: 'ratings',
    trackingName: 'PROVIDER RATING',
    label: 'Rating',
    placement: 'bottom-start',
    resetFilters: () => {
      setFilters({
        ...filters,
        ratings: []
      });
    },
    resetText: 'Clear'
  },
  'Term length': {
    component: (
      <TermLength
        filters={filters}
        plans={plans}
        setFilters={setFilters}
        terms={terms}
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: filters.terms.length > 0,
    number: filters.terms.length,
    filterType: 'terms',
    trackingName: 'TERM LENGTH',
    label: 'Term length',
    placement: 'bottom-end',
    resetFilters: () => {
      setFilters({
        ...filters,
        terms: []
      });
    },
    resetText: 'Clear'
  },
  'Rate type': {
    component: (
      <RateType
        filters={filters}
        rates={rates}
        setFilters={setFilters}
      />
    ),
    hasIcon: false,
    icon: <></>,
    isActive: filters.rateTypes.length > 0,
    number: filters.rateTypes.length,
    filterType: 'rateTypes',
    trackingName: 'RATE TYPE',
    label: 'Rate type',
    placement: 'bottom-end',
    resetFilters: () => {
      setFilters({
        ...filters,
        rateTypes: []
      });
    },
    resetText: 'Clear'
  },
});

PillsDropdownObject.propTypes = {
  allPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filters: PropTypes.shape({}).isRequired,
  isNTX: PropTypes.bool.isRequired,
  plans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rates: PropTypes.arrayOf(PropTypes.oneOf(['Fixed', 'Variable', 'Indexed'])).isRequired,
  setFilters: PropTypes.func.isRequired,
  setUsage: PropTypes.func.isRequired,
  terms: PropTypes.arrayOf(PropTypes.string).isRequired,
  site: PropTypes.string,
  setSelectedUtility: PropTypes.func,
};

PillsDropdownObject.defaultProps = {
  site: '',
};

export default PillsDropdownObject;
