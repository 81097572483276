import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { ZipSearched } from '@rvpower/track';
import { Sites, assembleEPInput } from '@rvpower/constants';
import {
  getZip,
  getComparisonPartnerId,
  getZipModal
} from '../js/project/get-query-params';

// React Components
import Grid from './Grid';
import GridNew from './grid-v2/GridNew';
import TxuGrid from './txu-grid/TxuGrid';

import Loader from './Loader';
import Error from './Error';
import MaintenanceMode from './MaintenanceMode';
import ProductDetailPage from './product-detail/ProductDetailPage';
import { FuseProvider } from './FuseNumbers';
import ZipModal from './ZipModal';
import { usePreamp } from './preamp';
import { energyTracking } from '../js/project/tagular/beam';
import { useMonarch } from './monarch';

const getConfig = (configs = [], id) => {
  let config = configs.find((c) => c.name === id);
  if (!config) {
    config = configs.find((f) => f.name === 'default');
  }
  return config;
};

function GridQuery(props) {
  const { site, fuseData } = props;

  const isTxu = site === Sites.TXU;
  const isSOE = site === Sites.SOE;

  const { rules } = useMonarch();
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const coreLogicBool = monarchCoreLogic || coreLogicPreamp;

  const txuGridRedesign = usePreamp('TXU Grid Redesign') || false;


  const params = new URLSearchParams(window.location.search);
  const ESIID = params.get('esiid');

  const comparisonPartnerId = getComparisonPartnerId();

  const compIdVariable = comparisonPartnerId
    ? ', $comparisonPartnerId: ID!'
    : '';
  const comparisonPlansQuery = `comparisonPlans(supplier: $comparisonPartnerId) {
    id
    name
    unitOfMeasure
    documents {
      type
      url
    }
    supplier {
      id
      name
    }
    utility {
      id
      name
    }
    rates {
      price
      type
    }
    term {
      length
      type
    }
    fees {
      type
      amount
    }
    type
    active
  }`;
  const utilitiesQuery = gql`
    query Utilities($zipCode: String!, $site: String!) {
      utilities(zipCode: $zipCode) {
        id
        name
      }
      gridFeaturedConfigs(site: $site) {
        id
        name
        site
        utilities {
          utilityId
          featuredPlans {
            planId
            labelId
            usageId
            distinction
          }
        }
      }
      gridListConfigs(site: $site) {
        id
        name
        site
        filters {
          features
          providers
          rateTypes
          customPlanOrder
          terms {
            length
            type
          }
          usage
        }
        sort
        sponsoredPlanId
      }
      isCallCenterOpen
      siteSettings(site: $site) {
        disableCartLinks
        disablePhoneNumbers
        maintenanceMode
      }
      zipCodeInfo(zipCode: $zipCode) {
        state
        city
      }
    }
  `;

  const utilityQuery = gql`
    query plans(
      $utilityId: ID! ${compIdVariable},
      $electricPhoenixInput: ElectricPhoenixInput,
      $businessUnitId: String,
      $zipCode: String,
      ${ESIID ? '$esiid: ID,' : ''},
      ${ESIID && coreLogicBool ? '$runCoreLogic: Boolean,' : ''}
    ) {
      utility(
        id: $utilityId,
        ${ESIID ? 'esiid: $esiid' : ''}
      ) {
        id
        name
        electricPhoenixData(electricPhoenixInput: $electricPhoenixInput) {
          annualUsage
          averageCost
          monthlyUsage {
              cost
              month
              usage
          }
          annualCost
        }
        plans(
          businessUnitId: $businessUnitId,
          electricPhoenixInput: $electricPhoenixInput,
          zipCode: $zipCode,
          ${ESIID ? 'esiid: $esiid' : ''},
          ${ESIID && coreLogicBool ? 'runCoreLogic: $runCoreLogic,' : ''}
        ) {
          id
          description
          secondaryDescription
          pricePerKwh
          documents {
            type
            url
          }
          name
          unitOfMeasure
          supplier {
            id
            isPartner
            logo
            name
            parentId
            phone
            whatToKnow
            ratings {
              customerService
              onlineAccessibility
              businessHistory
              overallScore
              planOptions
            }
            controls {
              disableGrid: disabled(source: "grid")
              disableCart: disabled(source: "cart")
              disableElectricPhoenix: disabled(source: "electric-phoenix")
              disablePhoneNumbers: disabled(source: "phone-numbers")
              disableCartLinks: disabled(source: "cart-links")
            }
          }
          incentives {
            generalIncentive {
              offerText
              showIncentive
              disclaimer
            }
            singleFamilyIncentive {
              offerText
              showIncentive
              disclaimer
            }
            multiFamilyIncentive {
              offerText
              showIncentive
              disclaimer
            }
          }
          electricPhoenixData {
            annualCost
            annualUsage
            monthlyUsage {
                cost
                month
                usage
            }
            averageCost
          }
          ${
  ESIID
    ? `
            clCalculatedData{
              clBuildingSQFT
              estimatedKWHUsage
              estimatedPricePerKWH
            }
          `
    : ''
}
          rates {
            price
            type
          }
          term {
            length
            type
          }
          type
          fees {
            type
            amount
            monthly
          }
          features
          cartActive
          cartLink
          badges {
            id
            name
            tooltip
            source
            attributeValue
          }
          electricPhoenixActive
          phoneActive
          active
          hideOnGrid
          utility {
            id
          }
          renewablePercentage
          harbingerIdentifiers {
              pointInTimeId
              lifetimeId
          }
          billCredits {
            amount
            startKwh
            endKwh
          }
        }
        ${comparisonPartnerId ? comparisonPlansQuery : ''}
      }
    }
  `;

  // Update fuse elements using utm medium from TXU
  // let txuFuseData;
  // const utmMedium = localStorage.getItem('utmMedium');
  // // if utm flag exists in local storage, create var
  // if (utmMedium) {
  //   txuFuseData = fuseData;
  //   // standard plans
  //   txuFuseData.dataFuseName = `TXU-Grid-${utmMedium}-Test`;
  //   // featured plans
  //   if (txuFuseData.featuredCardsFuseData) {
  //     txuFuseData.featuredCardsFuseData.dataFuseName = `TXU-Featured-Grid-${utmMedium}-Test`;
  //   }
  // }

  const [selectedUtility, setSelectedUtility] = useState(false);
  const [featuredConfigId, setFeaturedConfigId] = useState('default');

  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;

  const preampListConfig = usePreamp('Grid List Config');
  const monarchListConfig = rules?.source?.gridListConfig?.value?.data?.gridListConfig;
  const listConfigId = preampListConfig || monarchListConfig || 'default';

  const zipModal = getZipModal();
  const zipCode = getZip(site) || (zipModal ? '77007' : '');

  const productDetailPageActive = params.get('pdp') === 'true';
  const SOEesiid = isSOE ? ESIID : '';

  useEffect(() => {
    if (zipCode) ZipSearched(zipCode);
  }, [zipCode]);

  const businessUnitId = isTxu ? '4' : '2';

  const electricPhoenixInput = assembleEPInput(params);
  const { data = {}, error, loading } = useQuery(utilitiesQuery, {
    variables: {
      zipCode,
      site,
      comparisonPartnerId,
      businessUnitId,
      zipModal,
      electricPhoenixInput
    }
  });

  const preampFeaturedConfig = usePreamp('Grid Featured Config');
  const monarchFeaturedConfig = rules?.source?.gridFeaturedConfig?.value?.data?.gridFeaturedConfig;
  const featuredConfigModifier = preampFeaturedConfig || monarchFeaturedConfig || 'default';

  useEffect(() => {
    if (isNTX) {
      const selectedUtilityInstance = data?.utilities?.filter((utility) => utility.id === selectedUtility)[0];
      window.monarch('ruleset', 'gridGlobalRules', { ntxUtility: selectedUtilityInstance?.name }, {}, (err, result) => {
        if (err) {
          console.error('Monarch Error', err);
          setFeaturedConfigId('default');
          return;
        }

        setFeaturedConfigId(result?.ntxGridFeaturedConfig?.data?.gridFeaturedConfig || 'default');
      });
    } else if (featuredConfigModifier) {
      setFeaturedConfigId(featuredConfigModifier);
    }
  }, [selectedUtility]);

  const {
    data: utilityData = {},
    error: utilityError,
    loading: utilityLoading
  } = useQuery(utilityQuery, {
    skip: !selectedUtility,
    variables: {
      utilityId: selectedUtility,
      comparisonPartnerId,
      electricPhoenixInput,
      zipCode,
      ...(isSOE && ESIID ? { esiid: ESIID } : {}),
      ...(coreLogicBool && ESIID ? { runCoreLogic: true } : {})
    }
  });

  // Save a few things to localStorage to access throughout the app later
  if (utilityData?.utility?.plans?.length) {
    const { clCalculatedData } = utilityData?.utility?.plans[0];

    localStorage.setItem('clBuildingSQFT', clCalculatedData?.clBuildingSQFT);
    localStorage.setItem(
      'estimatedKWHUsage',
      clCalculatedData?.estimatedKWHUsage
    );
  }

  // set the default selectedUtility if we only have utility provider
  useEffect(() => {
    if (!selectedUtility && data?.utilities?.length === 1) {
      setSelectedUtility(data.utilities[0].id);
    }
  }, [data.utilities]);

  // Spanish Session Check
  useEffect(() => {
    if (sessionStorage.getItem('languageTracked')) return;

    const { _Cohesion: cohesion } = window;
    const { language } = navigator;
    const spanishSessionUTMValues = ['18339492849', '18346796633', '19256696813', '3580139', '3580140', '3580258'];

    const isSpanishSession = language.includes('es')
    || spanishSessionUTMValues.includes(
      params.get('utm_mcid') || params.get('utm_cmpid')
    );

    const payload = {
      activityDateTime: new Date().toISOString(),
      webContext: {
        anonymousId: cohesion.anonymousId,
        websessionId: cohesion.sessionId,
      },
      languageIdentifier: isSpanishSession ? 'sp' : 'en',
    };

    energyTracking('LanguageIdentifier', payload);
    sessionStorage.setItem('languageTracked', 'true');
  }, []);

  const showZipModal = isTxu && zipModal;
  const gridFeaturedConfigs = data?.gridFeaturedConfigs;

  // @todo: Not sure if this is being used anymore, would like to remove if not.
  const renderZipGridComponent = () => {
    if (!isTxu) {
      return (
        <GridNew
          site={site}
          isCallCenterOpen={isTxu}
          fuseData={fuseData}
          utilities={[]}
          zipCode={zipCode}
          ESIID={SOEesiid}
          featuredConfig={(getConfig(gridFeaturedConfigs || [], featuredConfigId))}
          listConfig={getConfig(
            data.gridListConfigs || [],
            listConfigId
          )}
        />
      );
    }
    if (isTxu && txuGridRedesign) {
      return (
        <TxuGrid
          site={site}
          isCallCenterOpen={isTxu}
          utilities={[]}
          zipCode={zipCode}
          ESIID={SOEesiid}
          featuredConfig={getConfig(gridFeaturedConfigs || [], featuredConfigId)}
          listConfig={getConfig(
            data.gridListConfigs || [],
            listConfigId
          )}
        />
      );
    }
    return (
      <Grid
        site={site}
        isCallCenterOpen={isTxu}
        utilities={[]}
        zipCode={zipCode}
        ESIID={SOEesiid}
        featuredConfig={getConfig(gridFeaturedConfigs || [], featuredConfigId)}
        listConfig={getConfig(
          data.gridListConfigs || [],
          listConfigId
        )}
      />
    );
  };

  if (!zipCode) {
    return (
      <div>
        <FuseProvider
          fuseData={fuseData}
          isOpen={data.isCallCenterOpen}
          isTxu={isTxu}
        >
          {productDetailPageActive ? (
            <ProductDetailPage site={site} zipCode={zipCode} />
          ) : (
            <>
              {renderZipGridComponent()}
            </>

          )}
        </FuseProvider>
      </div>
    );
  }

  if (loading || utilityLoading) return <Loader />;
  if (data?.siteSettings?.maintenanceMode) return <MaintenanceMode site={site} fuseData={fuseData} />;
  if (error || utilityError) return <Error error={error || utilityError} fuseData={fuseData} />;

  const { disablePhoneNumbers, disableCartLinks } = data.siteSettings;

  const utility = {
    ...utilityData?.utility,
    epData: {
      ...utilityData?.utility?.electricPhoenixData,
      input: {
        ...electricPhoenixInput
      }
    }
  };

  const epData = {
    input: {
      ...electricPhoenixInput
    }
  };
  // This one appears to be the one being used on the grid currently
  const renderGridComponent = () => {
    if (!isTxu) {
      return (
        <GridNew
          site={site}
          isCallCenterOpen={isTxu || data.isCallCenterOpen}
          fuseData={fuseData}
          disableCartLinks={disableCartLinks}
          disablePhoneNumbers={disablePhoneNumbers}
          utilities={data.utilities.map((util) => ({
            ...util,
            epData
          }))}
          ESIID={SOEesiid}
          zipCode={zipCode}
          featuredConfig={(getConfig(gridFeaturedConfigs || [], featuredConfigId))}
          listConfig={getConfig(data.gridListConfigs, listConfigId)}
          city={data.zipCodeInfo?.city}
          electricPhoenixInput={electricPhoenixInput}
          selectedUtility={selectedUtility}
          setSelectedUtility={setSelectedUtility}
          utility={utility}
        />
      );
    }
    if (isTxu && txuGridRedesign) {
      return (
        <TxuGrid
          site={site}
          isCallCenterOpen={isTxu || data.isCallCenterOpen}
          disableCartLinks={disableCartLinks}
          disablePhoneNumbers={disablePhoneNumbers}
          utilities={data.utilities.map((util) => ({
            ...util,
            epData
          }))}
          ESIID={SOEesiid}
          zipCode={zipCode}
          featuredConfig={getConfig(gridFeaturedConfigs, featuredConfigId)}
          listConfig={getConfig(data.gridListConfigs, listConfigId)}
          city={data.zipCodeInfo?.city}
          electricPhoenixInput={electricPhoenixInput}
          selectedUtility={selectedUtility}
          setSelectedUtility={setSelectedUtility}
          utility={utility}
        />
      );
    }
    return (
      <Grid
        site={site}
        isCallCenterOpen={isTxu || data.isCallCenterOpen}
        disableCartLinks={disableCartLinks}
        disablePhoneNumbers={disablePhoneNumbers}
        utilities={data.utilities.map((util) => ({
          ...util,
          epData
        }))}
        ESIID={SOEesiid}
        zipCode={zipCode}
        featuredConfig={(getConfig(gridFeaturedConfigs || [], featuredConfigId))}
        listConfig={getConfig(data.gridListConfigs, listConfigId)}
        city={data.zipCodeInfo?.city}
        electricPhoenixInput={electricPhoenixInput}
        selectedUtility={selectedUtility}
        setSelectedUtility={setSelectedUtility}
        utility={utility}
      />
    );
  };

  return (
    <FuseProvider
      fuseData={fuseData}
      isOpen={data.isCallCenterOpen}
      isTxu={isTxu}
      disablePhoneNumbers={disablePhoneNumbers}
    >
      {productDetailPageActive ? (
        <ProductDetailPage site={site} zipCode={zipCode} />
      ) : (
        <>
          {showZipModal && <ZipModal fuseData={fuseData} />}
          {renderGridComponent()}
        </>
      )}
    </FuseProvider>
  );
}

export default GridQuery;
