/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { usePreamp } from '../../preamp';

import closeIcon from '../../../svg/close-button-white.svg';
import Checkbox from '../../Checkbox';
import FeaturedCardOverlay from '../../FeaturedCardOverlay';

import CTA from '../sub-components/Cta';
import FeaturedCardLabel from '../sub-components/FeatureCardLabel';
import PricePerKwh from '../sub-components/PricePerKwh';
import SupplierRatings from '../sub-components/SupplierRatings';
import Badge from '../../Badge';

const TXUFeaturedCard = ({
  addToCompare,
  checkbox,
  comparedPlans,
  disableCartLinks,
  featuredCard,
  gridComparisonToolActive,
  onOrderOnline,
  plan,
  planDetailsToggle,
  removeFromCompare,
  showPlanDetails,
  showRatings,
  site,
  swapExperience,
  dwellingType,
}) => {
  const {
    description,
    name,
    supplier,
    type,
    incentives,
    badges
  } = plan;

  /** Incentives:
  * Check if home is a single or multi family to display incentive offer text */
  const incentiveOfferText = dwellingType === 'multi'
    ? incentives.multiFamilyIncentive.offerText
    : incentives.singleFamilyIncentive.offerText;
  const offerIncentivesPreamp = usePreamp('Offer Incentives');
  const TXUBadgesPreamp = usePreamp('Txu badges');

  const incentiveActive = offerIncentivesPreamp && incentiveOfferText !== '';

  return (
    <article className={`featured-card ${gridComparisonToolActive ? 'gct-active' : ''}`} ref={featuredCard}>
      <FeaturedCardLabel plan={plan} />
      <div className="overlay__close-button-container">
        <button
          type="button"
          className={planDetailsToggle ? 'overlay__button overlay__button--active' : 'overlay__button'}
          onClick={
            (e) => {
              showPlanDetails(e);
            }
          }
        >
          <img
            className="overlay__close-button"
            src={closeIcon}
            alt="close icon"
          />
        </button>
      </div>
      <div className="featured-card--overlay-container">
        {incentiveActive && (
          <div className="incentive__banner incentive__banner--featured">LIMITED TIME OFFER</div>
        )}
        <div className="featured-card__section featured-card__plan-description-group">
          <div className="name">
            <p className="name__provider">
              {name}
            </p>
            {incentiveActive && (
              <p className="incentive__offer-text">
                +
                {incentiveOfferText}
                *
              </p>
            )}
          </div>
          {swapExperience ? (
            <div className="featured-card__plan-details-container--txu">
              <span className="feature-card_description">{description}</span>
              <button
                type="button"
                className="featured-card__more-info-button-position--txu"
                onClick={(e) => {
                  showPlanDetails(e);
                }}
              >
                MORE INFO
              </button>
            </div>
          ) : (
            <PricePerKwh
              site={site}
              plan={plan}
              flexRow
              isTXU
              className="card__section-item"
              featuredCard={featuredCard}
            />
          )}
        </div>
        <div
          className={`
            featured-card__section
            logo__container
            u-display-flex
            u-align-items-center
            ${showRatings ? 'logo__container--ratings' : ''}
          `}
        >
          {showRatings ? (
            <div className="logo-with-ratings">
              <SupplierRatings
                plan={plan}
                site={site}
                type={type}
                enableRatings
                className="card__section-item"
              />
            </div>
          ) : (
            <img className="logo__image" src={supplier.logo} alt={supplier.name} />
          )}
        </div>
        {swapExperience ? (
          <PricePerKwh
            plan={plan}
            site={site}
            flexRow
            isTXU
            className="card__section-item"
          />
        ) : (
          <div className="featured-card__plan-details-container--txu contains_badge_card">
            <button
              type="button"
              className="featured-card__more-info-button-position--txu"
              onClick={(e) => {
                showPlanDetails(e);
              }}
            >
              MORE INFO
            </button>
            <span className="feature-card_description">{description}</span>
            {(TXUBadgesPreamp && badges?.length) ? (
              <Badge type={badges[0].name}>
                {badges[0].attributeValue}
              </Badge>
            ) : null}
          </div>
        )}
        {gridComparisonToolActive && (
          <div className="featured-card__section checkbox-row u-display-flex u-flex-direction-column">
            <Checkbox
              key={checkbox.name}
              checkbox={checkbox}
              addItem={addToCompare}
              removeItem={removeFromCompare}
              comparedPlans={comparedPlans}
              gridComparisonToolActive={gridComparisonToolActive}
            />
          </div>
        )}
        <div className="featured-card__section u-display-flex u-flex-direction-column">
          <CTA
            plan={plan}
            site={site}
            disableCartLinks={disableCartLinks}
            onOrderOnline={onOrderOnline}
            className="card__section-item"
            featuredCard
          />
        </div>
        <FeaturedCardOverlay
          site={site}
          plan={plan}
          type={type}
          activeClass={planDetailsToggle}
          incentiveActive={incentiveActive}
        />
      </div>
    </article>
  );
};

TXUFeaturedCard.propTypes = {
  addToCompare: PropTypes.func.isRequired,
  checkbox: PropTypes.shape({}).isRequired,
  comparedPlans: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disableCartLinks: PropTypes.bool.isRequired,
  featuredCard: PropTypes.shape({}).isRequired,
  gridComparisonToolActive: PropTypes.bool.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  showRatings: PropTypes.bool.isRequired,
  site: PropTypes.string.isRequired,
  swapExperience: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]).isRequired,
  dwellingType: PropTypes.string.isRequired,
};

export default TXUFeaturedCard;
