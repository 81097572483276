/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

import { Sites } from '@rvpower/constants';
import NTXCardV2 from './NTXCardV2';
import TXUCard from './TXUCard';
import BasicCard from './BasicCard';
import EPCard from './EPCard';
import CardV2 from './CardV2';
import TXUPlanDetails from '../../txu-grid/TXUPlanDetails';
import { usePreamp } from '../../preamp';
import TXUCardV2 from './TXUCardV2';

/**
 * Higher order component to determine which template card to render
 * @param {*} props
 */
const TemplateCard = (props) => {
  const {
    ESIID,
    disableCartLinks,
    electricPhoenixActive,
    electricPhoenixInput,
    electricPhoenixToggleActive,
    epData,
    isNTX,
    onOrderOnline,
    plan,
    planIndex,
    site,
    dwellingType,
    newGridEnabled = false,
    hmcData,
    hasDefaultFilters,
    sort,
  } = props;
  const isSOE = site === Sites.SOE;
  const SOEesiid = isSOE ? ESIID : '';
  const txuGridRedesign = usePreamp('TXU Grid Redesign') || false;

  switch (site.toLowerCase()) {
    case newGridEnabled && Sites.SOE:
    case newGridEnabled && Sites.NEW_TXER:
    case newGridEnabled && Sites.NEW_CTXP:
    case newGridEnabled && !isNTX && Sites.CHOOSE:
      return (
        <CardV2
          ESIID={SOEesiid}
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
          planIndex={planIndex}
          hmcData={hmcData}
          hasDefaultFilters={hasDefaultFilters}
          sort={sort}
        />
      );
    case Sites.TXU:
      // @TODO: Hook up electricPhoenixActive props
      if (txuGridRedesign) {
        return (
          <TXUCardV2
            plan={plan}
            site={site}
            onOrderOnline={onOrderOnline}
            disableCartLinks={disableCartLinks}
          />
        );
      }

      return (
        <>
          {!txuGridRedesign ? (
            <TXUCard
              plan={plan}
              site={site}
              onOrderOnline={onOrderOnline}
              disableCartLinks={disableCartLinks}
              dwellingType={dwellingType}
            />
          )
            : (
              <TXUPlanDetails
                ESIID={SOEesiid}
                plan={plan}
                site={site}
                onOrderOnline={onOrderOnline}
                disableCartLinks={disableCartLinks}
                dwellingType={dwellingType}
              />
            )}
        </>
      );
    case newGridEnabled && isNTX && Sites.CHOOSE:
      return (
        <NTXCardV2
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
        />
      );
    default:
      // Sites.CHOOSE
      if (electricPhoenixActive) {
        return (
          <EPCard
            plan={plan}
            planIndex={planIndex}
            site={site}
            onOrderOnline={onOrderOnline}
            electricPhoenixActive={electricPhoenixActive}
            electricPhoenixToggleActive={electricPhoenixToggleActive}
            electricPhoenixInput={electricPhoenixInput}
            epData={epData}
            disableCartLinks={disableCartLinks}
          />
        );
      }

      return (
        <BasicCard
          ESIID={SOEesiid}
          plan={plan}
          site={site}
          onOrderOnline={onOrderOnline}
          disableCartLinks={disableCartLinks}
        />
      );
  }
};

TemplateCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  electricPhoenixActive: PropTypes.bool,
  isNTX: PropTypes.bool,
  planIndex: PropTypes.number,
  disableCartLinks: PropTypes.bool,
  electricPhoenixToggleActive: PropTypes.bool,
  electricPhoenixInput: PropTypes.shape({}),
  epData: PropTypes.shape({}),
  dwellingType: PropTypes.string.isRequired
};

TemplateCard.defaultProps = {
  electricPhoenixActive: false,
  planIndex: 0,
  disableCartLinks: false,
  electricPhoenixToggleActive: false,
  electricPhoenixInput: {},
  epData: {},
  isNTX: false,
};

export default TemplateCard;
