import React from 'react';
import TxuLogo from '../../../images/txu-white-logo.svg';
import TxuAccreditedLogo from '../../../images/txu-accredited-logo.svg';
import TxuFooterGraphics from '../../../images/txu-footer-graphics.png';
import { useMonarch } from '../monarch';

const year = new Date().getFullYear();

export default function TxuFooter() {
  const { rules } = useMonarch();
  const singleFamilyDisclaimer = rules?.source?.trafficFlow?.value?.singleFamilyDisclaimer;
  const multiFamilyDisclaimer = rules?.source?.trafficFlow?.value?.multiFamilyDisclaimer;

  return (
    <footer className="footer">
      <img
        src={TxuFooterGraphics}
        alt="footer graphics"
        className="txu-footer-graphics"
      />
      <div className="footer-container">
        <div className="footer-wrapper">
          <div className="footer-contents-container">
            <img src={TxuLogo} alt="txu logo" className="txu-footer-logo" />
            <ul className="main-footer-links">
              <li>
                <a href="https://shop.txu.com/" target="_blank" rel="noopener noreferrer">
                  Home
                </a>
              </li>
              <li>
                <a href="https://shop.txu.com/why-txu/" target="_blank" rel="noopener noreferrer">
                  Why TXU Energy
                </a>
              </li>
              <li>
                <a href="https://www.txu.com/help/support/contact-us" target="_blank" rel="noopener noreferrer">
                  Contact Us
                </a>
              </li>
            </ul>
            <ul className="footer-links">
              <li>
                <a href="https://www.txu.com/privacy-notice" target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="https://www.txu.com/terms-of-use" target="_blank" rel="noopener noreferrer">
                  Terms & Conditions
                </a>
              </li>
            </ul>
            <p className="copyright">
              ©
              {year}
              {' '}
              TXU Energy Retail Company LLC REP #10004
            </p>
            <img
              src={TxuAccreditedLogo}
              alt="txu accredited logo"
              className="txu-accredited-logo"
            />
          </div>
          <div className="footer-text">
            <p>
              *Price Protect Promise
              <sup>SM</sup>
              {' '}
              applies to residential term contracted
              customers on fixed rate products. The only circumstance in which the
              price could change during the 12-month minimum term is to reflect
              actual changes in law or regulatory charges as described in the
              Electricity Facts Label (EFL).
            </p>
            <p>
              **Total Satisfaction Guarantee - Just call us and cancel within 60
              days of your sign-up date and, even if you signed a contract, we’ll
              waive any early cancellation fees. New TXU Energy residential
              customers only. Cancellation fee applies if you cancel after 60
              days. You remain responsible for any billed and unbilled charges.
            </p>
            <p>
              1Same-day service available for customers with AMS meters who sign
              up before 6:30 p.m. Not applicable on Sundays, holidays or in the Lubbock area.
            </p>
            <p>
              2Average based on official EIA research.
            </p>
            {singleFamilyDisclaimer ? (
              <p>
                {singleFamilyDisclaimer?.pre}
                <a
                  target="_blank"
                  href={singleFamilyDisclaimer?.link}
                  rel="noopener noreferrer"
                >
                  {singleFamilyDisclaimer?.linkText}
                </a>
                {singleFamilyDisclaimer?.post}
              </p>
            ) : null}

            {multiFamilyDisclaimer ? (
              <p>
                {multiFamilyDisclaimer?.pre}
                <a
                  target="_blank"
                  href={multiFamilyDisclaimer?.link}
                  rel="noopener noreferrer"
                >
                  {multiFamilyDisclaimer?.linkText}
                </a>
                {multiFamilyDisclaimer?.post}
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </footer>
  );
}
