/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  UsageAmounts, Sites,
} from '@rvpower/constants';
import { usePreamp } from '../../preamp';

import TXURedesignFeaturedCardDefault from './TXURedesignFeaturedCardDefault';
import TXURedesignFeaturedCardVariant from './TXURedesignFeaturedCardVariant';

const TXURedesignFeaturedCard = ({
  featuredCard,
  onOrderOnline,
  plan,
  planDetailsToggle,
  showPlanDetails,
  site,
  dwellingType
}) => {
  const {
    description, name, type, incentives, badges, cartUrl, usageId
  } = plan;

  /** Incentives:
   * Check if home is a single or multi family to display incentive offer text */
  const incentiveOfferText = dwellingType === 'multi'
    ? incentives.multiFamilyIncentive.offerText
    : incentives.singleFamilyIncentive.offerText;
  const offerIncentivesPreamp = usePreamp('Offer Incentives');
  const TXUBadgesPreamp = usePreamp('Txu badges');
  const txuSimplifiedFeaturedCards = usePreamp('TXU Simplified Featured Cards', true) || { option: 'default' };

  const incentiveActive = offerIncentivesPreamp && incentiveOfferText !== '';

  const { termText } = plan;

  const isTXU = site === Sites.TXU;
  const editableTxuCartCTAAsset = '[editable] Cart CTA';
  let txuCartCta;
  txuCartCta = usePreamp('Cart CTA') || '';
  txuCartCta = usePreamp('Cart CTA - editable', editableTxuCartCTAAsset);
  const txuWordToUse = txuCartCta || 'Order Online';
  const isTxuPreampWord = isTXU && txuWordToUse;

  const [usage, setUsage] = useState('1000');

  useEffect(() => {
    if (usageId && usageId !== '') {
      setUsage(UsageAmounts[usageId].label);
    }
  }, [usageId]);

  function featuredCardUrl(cartUrlLink, usageAmount) {
    return cartUrlLink.replace(/rateType=[0-9]*/, `rateType=${usageAmount}`);
  }

  return (
    <>
      {txuSimplifiedFeaturedCards?.option === 'default' ? (
        <TXURedesignFeaturedCardDefault
          featuredCard={featuredCard}
          onOrderOnline={onOrderOnline}
          planDetailsToggle={planDetailsToggle}
          showPlanDetails={showPlanDetails}
          plan={plan}
          site={site}
          dwellingType={dwellingType}
        />
      ) : (
        <TXURedesignFeaturedCardVariant
          featuredCard={featuredCard}
          onOrderOnline={onOrderOnline}
          planDetailsToggle={planDetailsToggle}
          showPlanDetails={showPlanDetails}
          description={description}
          name={name}
          type={type}
          plan={plan}
          badges={badges}
          cartUrl={cartUrl}
          TXUBadgesPreamp={TXUBadgesPreamp}
          incentiveActive={incentiveActive}
          termText={termText}
          usage={usage}
          isTxuPreampWord={isTxuPreampWord}
          featuredCardUrl={featuredCardUrl}
          site={site}
          variant={txuSimplifiedFeaturedCards?.option}
        />
      )}
    </>
  );
};

TXURedesignFeaturedCard.propTypes = {
  featuredCard: PropTypes.shape({}).isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    description: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    supplier: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  planDetailsToggle: PropTypes.bool.isRequired,
  showPlanDetails: PropTypes.func.isRequired,
  site: PropTypes.string.isRequired,
  dwellingType: PropTypes.string.isRequired
};

export default TXURedesignFeaturedCard;
