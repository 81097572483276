import React from 'react';
import { PropTypes } from 'prop-types';
import generateClasses from '../utils';
import Badge from '../../Badge';
import { usePreamp } from '../../preamp';

export default function Description({ plan, className }) {
  const classString = generateClasses(className, 'description');
  const TXUBadgesPreamp = usePreamp('Txu badges');

  return (
    <>
      <div
        className="card__section-item description u-display-none
      u-display-flex@lg u-align-items-center contains_badge"
      >
        {plan.description}
        {(TXUBadgesPreamp && plan?.badges?.length) ? (
          <Badge type={plan.badges[0].name}>
            {plan.badges[0].attributeValue}
          </Badge>
        ) : null}

      </div>
      <div
        className="card__section-item logo__container
        u-display-flex u-flex-direction-column u-align-items-flex-end
        u-justify-content-flex-start u-align-items-center@md u-justify-content-center@md
        ep-card--hide"
      >
        <p className={`${classString} description`}>{plan.description}</p>
      </div>
    </>
  );
}

Description.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  className: PropTypes.string
};

Description.defaultProps = {
  className: ''
};
