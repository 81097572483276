const splitAndLowerCase = (array) => {
  if (!Array.isArray(array)
    || array?.length === 0
    || (typeof array?.[0] === 'number')
  ) return '';

  /* eslint-disable-next-line arrow-body-style */
  return array?.[0].split(/(?=[A-Z])/).map((str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }).join(' ');
};

const createBillPriceLabel = (filters, currentDropdown) => {
  if (currentDropdown?.filterType !== 'billPrice') return '';

  const { minBillPrice } = filters?.billPrice;
  const { maxBillPrice } = filters?.billPrice;

  if (minBillPrice !== null || maxBillPrice !== null) {
    const formattedMinBillPrice = minBillPrice === null ? 'X' : minBillPrice;
    const formattedMaxBillPrice = maxBillPrice === null ? 'X' : maxBillPrice;

    return `$${formattedMinBillPrice} - $${formattedMaxBillPrice}`;
  }

  return '';
};

const createHomeSizeLabel = (filters, currentDropdown, usage) => {
  if (currentDropdown?.filterType !== 'usage') return '';

  const usageMap = {
    500: 'Apartment',
    750: 'Apartments',
    1000: 'Small home',
    1500: 'Small home',
    2000: 'Large home',
  };

  if ([500, 750, 1000, 1500, 2000]?.includes(filters?.usage)) {
    const returnValue = usageMap[filters?.usage];
    return returnValue;
  }

  if (filters?.usage > 0) {
    return `${filters.usage} ${usage}`;
  }

  return '';
};

const providersObject = (providers) => providers.reduce((acc, curr) => ({
  ...acc,
  [curr.id]: curr.name
}), {});

const createProvidersLabel = (filterValue, providers) => providers[filterValue?.[0]];

const createUtilityLabel = (currentDropdown, utility) => {
  if (currentDropdown?.filterType !== 'utilities') return '';
  if (utility.name) return utility.name;
  return '';
};

const pillIsActive = (value, filters) => {
  if (value.filterType === 'billPrice') {
    if (filters?.billPrice?.minBillPrice !== null || filters?.billPrice?.maxBillPrice !== null) {
      return true;
    }
  }

  if (value?.number > 0) {
    return true;
  }

  return false;
};

const formatLabel = (filters, label, currentDropdown, providers, usage, utility) => {
  const filterValue = filters?.[currentDropdown.filterType];
  const { providers: filterProviders } = filters;

  const invokeProviderObject = providersObject(providers);

  const filterLabels = {
    billPrice: createBillPriceLabel(filters, currentDropdown),
    features: splitAndLowerCase(filterValue),
    providers: createProvidersLabel(filterProviders, invokeProviderObject),
    terms: splitAndLowerCase(filterValue),
    rateTypes: splitAndLowerCase(filterValue),
    ratings: filterValue.toString(),
    usage: createHomeSizeLabel(filters, currentDropdown, usage),
    utility: createUtilityLabel(currentDropdown, utility),
    default: label
  };

  if (currentDropdown?.filterType === 'billPrice'
    && (filters?.billPrice?.minBillPrice !== null || filters?.billPrice?.maxBillPrice !== null)) {
    return filterLabels.billPrice;
  }

  if (currentDropdown?.filterType === 'usage') {
    return filterLabels.usage === 'Small home' ? 'Home size' : filterLabels.usage;
  }

  if (currentDropdown?.filterType === 'utilities') {
    return filterLabels.utility;
  }

  if (filterValue?.length > 0) {
    return filterLabels[currentDropdown.filterType] || filterLabels.default;
  }

  return label;
};

// Sort pill order based on what is active
const sortPills = (array, object) => [...array].sort((a, b) => {
  const bIsActive = object[b].isActive;
  const aIsActive = object[a].isActive;

  if (bIsActive > aIsActive) {
    return 1;
  }

  if (aIsActive > bIsActive) {
    return -1;
  }

  return 0;
});

export { formatLabel, pillIsActive, sortPills };
