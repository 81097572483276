import React from 'react';
import PropTypes from 'prop-types';
import productDisclosure from '../../js/project/product-disclaimers';
import TxuPDFLinks from './PDFLinks';
import close from '../../../images/close.svg';

export default function TxuOverlayContent({
  activeClass,
  plan,

  showPlanDetails
}) {
  const {
    secondaryDescription, name
  } = plan;

  const planDisclosure = productDisclosure(name);

  return (
    <div
      className={
        activeClass ? 'overlay active-overlay txu-redesign' : 'overlay'
      }
    >
      <div className="overlay__heading">
        {name}
        <button
          type="button"
          onClick={(e) => {
            showPlanDetails(e);
          }}
          className="close"
        >
          <img
            src={close}
            alt="close icon"
          />
        </button>
      </div>
      <div
        className="overlay__html-content"
        dangerouslySetInnerHTML={{ __html: secondaryDescription }}
      />
      <div
        className="overlay__html-disclosure"
        dangerouslySetInnerHTML={{ __html: planDisclosure }}
      />
      <ul className="overlay__pdf-list txu-redesign u-display-flex u-justify-content-space-between">
        <TxuPDFLinks plan={plan} isFeaturedCard />
      </ul>
    </div>
  );
}

TxuOverlayContent.propTypes = {
  activeClass: PropTypes.bool.isRequired,
  plan: PropTypes.shape({}).isRequired
};
