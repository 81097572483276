/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { usePreamp } from '../preamp';
import tracking from '../../js/project/tagular/tracking';
import PlanDetails from './PlanDetails';

import close from '../../svg/close.svg';
import Badge from '../Badge';

function TXUPlanDetails({ plan, site }) {
  const { name } = plan;
  const TXUBadgesPreamp = usePreamp('Txu badges');
  const [detailsOpen, setDetailsOpen] = useState(false);

  const handleOpenDetails = () => {
    setDetailsOpen(!detailsOpen);

    // Lock mobile viewport to prevent background from scrolling
    if (detailsOpen) document.body.classList.remove('grid-v2__mobile-locked');
    else document.body.classList.add('grid-v2__mobile-locked');

    tracking.elementClicked({
      webElement: {
        location: 'GRID',
        elementType: 'Button',
        text: 'MORE DETAILS'
      },
      actionOutcome: `Plan details ${detailsOpen ? 'closed' : 'expanded'}`
    });
  };

  return (
    <>
      <article className="txu-plan-card">
        <div className="plan-header">
          <h2>Savers Choice 12</h2>
          <div className="header-info">
            <p>Save more when you use more, with automatic bill relief</p>
            {TXUBadgesPreamp && plan?.badges?.length ? (
              <Badge type={plan.badges[0].name}>
                {plan.badges[0].attributeValue}
              </Badge>
            ) : null}
          </div>
          <button
            type="button"
            className="plan-details"
            onClick={() => handleOpenDetails()}
          >
            See Plan Details
          </button>
        </div>
        <div className="plan-body">
          <p>Save more when you use more, with automatic bill relief</p>
          {TXUBadgesPreamp && plan?.badges?.length ? (
            <Badge type={plan.badges[0].name}>
              {plan.badges[0].attributeValue}
            </Badge>
          ) : null}
        </div>
        <div className="plan-info plan-term">
          <div className="term">
            <div>
              {' '}
              <span>
                <b>Term</b>
                {' '}
              </span>
              {' '}
              <br />
              <span>Fixed rate</span>
            </div>
            <strong>12 months</strong>
          </div>
        </div>
        <div className="plan-info plan-price">
          <div className="price">
            <div>
              {' '}
              <span>
                <b>Price per kWh</b>
              </span>
              {' '}
              <br />
              <span>at 2000 kWh</span>
            </div>
            <strong>14.9¢</strong>
            <p className="price-term">
              Term Fixed Rate | 12 months
            </p>
            <div className="price-badge">
              {TXUBadgesPreamp && plan?.badges?.length ? (
                <Badge type={plan.badges[0].name}>
                  {plan.badges[0].attributeValue}
                </Badge>
              ) : null}
            </div>
          </div>
        </div>
        <div className="plan-footer">
          <span className="phone-number">877-238-1409</span>
          <button type="button" className="check-availability">Check Availability</button>
          <button
            type="button"
            className="plan-details"
            onClick={() => handleOpenDetails()}
          >
            See Plan Details
          </button>
        </div>
      </article>
      <article className="plan-card-v2">
        {detailsOpen && (
          <div className="plan-card-v2__details">
            <div className="plan-card-v2__details-header">
              {/* eslint-disable-next-line */}
              <img
                className="plan-card-v2__details-close"
                src={close}
                alt="Close"
                onClick={() => handleOpenDetails()}
              />
              <div className="plan-card-v2__details-title">{name}</div>
              <div className="plan-card-v2__details-subtitle">
                {plan.supplier.name}
              </div>
            </div>
            <div className="plan-card-v2__details-content">
              <PlanDetails plan={plan} site={site} />
            </div>
          </div>
        )}
      </article>
    </>
  );
}

TXUPlanDetails.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired
};

export default TXUPlanDetails;
