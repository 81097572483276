// Modules
import '@babel/polyfill';
import 'promise-polyfill/src/polyfill';

import { ApolloProvider } from '@apollo/react-hooks';
import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useAsync } from 'react-async-hook';
import createClient, { createReadOnlyToken } from '@rvpower/client';
import { Sites } from '@rvpower/constants';
import LogRocket from 'logrocket';

// React Components
import FeatureFlagsProvider from './components/feature-flags';
import GridQuery from './components/GridQuery';
import PlanCardsWidgetQuery from './components/plan-cards-widget/PlanCardsWidgetQuery';
import Loader from './components/Loader';
import Error from './components/Error';
import PreampProvider from './components/preamp';
import { MonarchProvider } from './components/monarch';
import AffiliateZip from './components/affiliate-zip/AffiliateZip';
import SiteContext from './components/SiteContext';

const gridElement = 'js-grid';

const siteSourceKeys = {
  [Sites.TXU]: '29681879-f9f6-4038-9fc6-453ecd87eba6',
  'Choose NTX': 'b14ed71b-0e67-4858-a9ef-703e26f270cd',
  default: 'ac87aced-3761-402f-af86-98de0a658e81'
};

const appsyncUrl = process.env.APPSYNC_URL;
const apiUrl = process.env.API_URL;

const App = (props) => {
  const { site, fuseData, planCardsWidget = false } = props;

  const params = new URLSearchParams(window.location.search);
  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;

  // Append click id to session storage
  const clickId = params.get('clickId');

  if (clickId) {
    sessionStorage.setItem('clickId', clickId);
  }

  /** Source will remain mutable (Ex: NTX Flow) */
  let source = siteSourceKeys[site] || siteSourceKeys.default;
  if (isNTX) source = siteSourceKeys['Choose NTX'];

  const { loading, error } = useAsync(() => createReadOnlyToken(apiUrl, source), [source]);

  if (loading) return <Loader />;
  if (error) return <Error />;

  const client = createClient(appsyncUrl, true, async () => {
    await createReadOnlyToken(apiUrl, source, true);
    return true;
  });

  return (
    <ApolloProvider client={client}>
      <div className="grid" id="grid">
        <FeatureFlagsProvider fuseData={fuseData}>
          <MonarchProvider>
            <PreampProvider>
              <SiteContext.Provider value={site}>
                {planCardsWidget ? (
                  <PlanCardsWidgetQuery
                    site={site}
                    fuseData={fuseData}
                  />
                ) : (
                  <GridQuery
                    site={site}
                    fuseData={fuseData}
                  />
                )}
              </SiteContext.Provider>
            </PreampProvider>
          </MonarchProvider>
        </FeatureFlagsProvider>
      </div>
    </ApolloProvider>
  );
};

const LogRocketInit = () => {
  if (process.env.APP_ENV !== 'production') return;

  LogRocket.init('xqocax/marketplace-prod-nnvz5');

  const cohesionCheck = setInterval(() => {
    if (window.cohesion) {
      window.cohesion('ready', () => {
        const { _Cohesion: cohesion, location } = window;

        LogRocket.identify(`${cohesion.sessionId}`, {
          name: cohesion.sessionId,
          site: location.host,
        });
      });
      clearInterval(cohesionCheck);
    }
  }, 100);

  setTimeout(() => {
    clearInterval(cohesionCheck);
  }, 15000);
};

window.initGrid = function initGrid(site, fuseData = {}) {
  // we don't need cartData anymore, but some sites might still pass it in
  if (arguments.length === 3) {
    // eslint-disable-next-line no-param-reassign,prefer-rest-params
    fuseData = arguments[2] || {};
  }

  const element = document.getElementById(gridElement);

  if (!element) {
    throw new Error(`Element with id ${gridElement} not found on page`);
  }

  LogRocketInit();

  Modal.setAppElement(element);

  ReactDOM.render(
    <App site={site} fuseData={fuseData} />,
    element
  );
};

window.initPlanCardsWidget = function initPlanCardsWidget(site, fuseData = {}) {
  const element = document.getElementById(gridElement);

  if (!element) {
    throw new Error(`Element with id ${gridElement} not found on page`);
  }

  LogRocketInit();

  Modal.setAppElement(element);

  ReactDOM.render(
    <App site={site} fuseData={fuseData} planCardsWidget />,
    element
  );
};

window.initAffiliateZip = function initAffiliateZip(clickId, site) {
  const zipElement = 'soe-affiliate-zip';
  const element = document.getElementById(zipElement);

  if (!element) {
    throw new Error(`Element with id ${zipElement} not found on page`);
  }

  ReactDOM.render(
    <AffiliateZip clickId={clickId} site={site} />,
    element
  );
};
