import React from 'react';

const TXUPlanDisclaimers = ({ disclaimers }) => {
  if (!disclaimers) {
    return null;
  }

  return (
    <div className="txu-plan-disclaimers">
      {disclaimers && (
        <p dangerouslySetInnerHTML={{ __html: disclaimers }} />
      )}
    </div>
  );
};

export default TXUPlanDisclaimers;
