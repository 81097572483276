const ParentPartnerIds = {
  NRG: '94f24556-1808-11e8-b121-84f43d51df6a'
};

const SupplierIds = {
  Bounce: '94f2328c-1808-11e8-b121-84f43d51df6a',
  Champion: '94f23124-1808-11e8-b121-84f43d51df6a',
  Chariot: '8b531534-213e-4998-aa56-e08c4530e5b2',
  Cirro: '94f242b8-1808-11e8-b121-84f43d51df6a',
  Constellation: '94f238e0-1808-11e8-b121-84f43d51df6a',
  Direct: '94f23476-1808-11e8-b121-84f43d51df6a',
  Discount: '94f2438a-1808-11e8-b121-84f43d51df6a',
  Everything: '94f2445c-1808-11e8-b121-84f43d51df6a',
  Express: '354dd8fd-a2e8-4fc3-b785-07f91b4f1ea1',
  FirstChoice: '94f23642-1808-11e8-b121-84f43d51df6a',
  FourChange: '94f23e3a-1808-11e8-b121-84f43d51df6a',
  Frontier: '94f23d4a-1808-11e8-b121-84f43d51df6a',
  Gexa: 'd755b664-2f50-4f6b-a464-45c32766e56a',
  GreenMountain: '94f241dc-1808-11e8-b121-84f43d51df6a',
  IberdrolaTexas: '9e8094c8-e49d-45e0-8b8d-abd6993191e6',
  NewLeaf: '94f23566-1808-11e8-b121-84f43d51df6a',
  Payless: '0221bfe0-916e-45bd-a60a-6be317f1ff5c',
  Pennywise: '94f2438a-1808-11e8-b121-84f43d51df6a',
  Pulse: 'b5a99952-8d17-45fb-b875-e7ff9d4234d4',
  Reliant: '94f240ec-1808-11e8-b121-84f43d51df6a',
  Rhythm: '8d0caea1-d4e1-4149-87b0-d29391db3c96',
  Spark: '94f24632-1808-11e8-b121-84f43d51df6a',
  TriEagle: '94f239c6-1808-11e8-b121-84f43d51df6a',
  Txu: '94f23390-1808-11e8-b121-84f43d51df6a',
  TxuBranded: '7df13460-9152-4ab8-9cfd-3fd5bd44d58c',
  Veteran: 'aa3addf0-39e0-43da-8e55-93517a8ccf7f',
  Yep: '94f23aa2-1808-11e8-b121-84f43d51df6a',
  BKVEnergy: '6848019b-0a61-4275-99c6-628bbf4ba43a',
};

// Utility names
const TEXAS_ELECTRICITY_RATES = 'TEXAS ELECTRICITY RATES';
const CENTERPOINT = 'CENTERPOINT';
const ONCOR = 'ONCOR';
const AEP_NORTH = 'AEP TEXAS NORTH';
const AEP_CENTRAL = 'AEP TEXAS CENTRAL';
const TEXAS_NEW_MEXICO = 'TEXAS NEW MEXICO POWER';
const TNMP = 'TNMP';

const UtilityNames = [
  TEXAS_ELECTRICITY_RATES,
  CENTERPOINT,
  ONCOR,
  AEP_NORTH,
  AEP_CENTRAL,
  TEXAS_NEW_MEXICO,
  TNMP
];

// Utility id & zip
// Used to determine the cart url parameters on
// CTXP & Choose rate tables
const UtilityMap = {
  [TEXAS_ELECTRICITY_RATES]: {
    zipCode: '75001',
    utilityId: 'ea0ad3a5-3fc6-4d9f-894a-e21a751c33fe'
  },
  [CENTERPOINT]: {
    zipCode: '77532',
    utilityId: '9b7d7ef7-c7a1-4330-bf70-d3b71c9a3e8d'
  },
  [ONCOR]: {
    zipCode: '79707',
    utilityId: 'ea0ad3a5-3fc6-4d9f-894a-e21a751c33fe'
  },
  [AEP_NORTH]: {
    zipCode: '79601',
    utilityId: '09f43c73-45ea-433e-a9fc-5a5d6ae2cd80'
  },
  [AEP_CENTRAL]: {
    zipCode: '77414',
    utilityId: '1c2c19b4-db14-4e24-ae7d-baf067ea4e7f'
  },
  [TEXAS_NEW_MEXICO]: {
    zipCode: '79785',
    utilityId: '6ca5c969-1e39-469a-a9a8-a9719e05048f'
  },
  [TNMP]: {
    zipCode: '79785',
    utilityId: '6ca5c969-1e39-469a-a9a8-a9719e05048f'
  }
};

module.exports = {
  ParentPartnerIds,
  SupplierIds,
  UtilityMap,
  UtilityNames
};
