import React, { useState } from 'react';
import ZipEntryModal from './ZipEntryModal';
import location from '../../../images/location.svg';
import TXUGoogleReview from './TXUGoogleReview';


export default function TXUHeroSection() {
  const [zipModalToggle, setZipModalToggle] = useState(false);

  const queryString = window.location.search;
  const queryParams = new URLSearchParams(queryString);
  const zipCode = queryParams.get('zipCode');

  const handleModalToggle = () => {
    setZipModalToggle(!zipModalToggle);
  };
  return (
    <>
      <div className="hero-container">
        <div className="hero-text-container">
          <div className="location">
            <p>
              Shop plans in
              {' '}
              {zipCode && zipCode}
            </p>
            <button
              onClick={() => {
                handleModalToggle();
              }}
              className="edit-location"
              type="button"
            >
              <img src={location} alt="location" />
              {' '}
              Edit Location
            </button>
          </div>
          <TXUGoogleReview />
        </div>
      </div>
      <ZipEntryModal
        active={zipModalToggle}
        handleModalToggle={handleModalToggle}
      />
    </>
  );
}
