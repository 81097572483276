/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { usePreamp } from '../../preamp';

import CardDetailsTrigger from '../../CardDetailsTrigger';

import PricePerKwh from '../sub-components/PricePerKwh';
import Cta from '../sub-components/Cta';
import Description from '../sub-components/Description';

function TXUCard({
  plan,
  site,
  onOrderOnline,
  disableCartLinks,
  dwellingType,
}) {
  const {
    termText,
    type,
    name,
    incentives,
  } = plan;

  const formattedPlanName = name.replace(/[(]/, '<span>(').replace(/[)]/, ')<span>');
  const swapExperience = usePreamp('Grid Swap Benefits and Price');

  /** Incentives:
  * Check if home is a single or multi family to display incentive offer text */
  const incentiveOfferText = dwellingType === 'multi'
    ? incentives.multiFamilyIncentive.offerText
    : incentives.singleFamilyIncentive.offerText;
  const offerIncentivesPreamp = usePreamp('Offer Incentives');
  const incentiveActive = offerIncentivesPreamp && incentiveOfferText !== '';

  return (
    <article
      className={`card ${incentiveActive ? 'card--incentive' : ''}`}
    >
      {incentiveActive && (
        <div className="incentive__banner">LIMITED TIME OFFER</div>
      )}
      <div
        className="card__section
          card__section--name
          u-display-flex@md
          u-justify-content-space-between@md
          u-align-items-center@md
          ep-card--hide"
      >
        <div className="name">
          <strong dangerouslySetInnerHTML={{ __html: formattedPlanName }} />
          {incentiveActive && (
            <p className="incentive__offer-text">
              +
              {incentiveOfferText}
              *
            </p>
          )}
        </div>
        <div className="term u-display-block@md">
          <strong>{`Term: ${termText} | ${type}`}</strong>
        </div>
      </div>
      <div
        className="card__section
          u-position-relative
          u-display-flex
          u-justify-content-space-between
          u-flex-wrap-wrap
          u-justify-content-flex-start@md
          u-align-items-center@md"
      >
        {swapExperience && (
          <Description
            plan={plan}
            className="txu__description"
          />
        )}
        <PricePerKwh
          plan={plan}
          site={site}
          className="card__section-item txu__price"
        />
        {!swapExperience && (
          <Description
            plan={plan}
            className="card__section-item txu__description"
          />
        )}
        <Cta
          plan={plan}
          site={site}
          disableCartLinks={disableCartLinks}
          onOrderOnline={onOrderOnline}
          className="card__section-item"
        />
      </div>

      {/* PLAN DETAILS SECTION */}
      <div className="card__checkbox-row">
        <div className="details-container">
          <CardDetailsTrigger
            plan={plan}
            site={site}
            incentiveActive={incentiveActive}
            // electricPhoenixActive={electricPhoenixActive}
          />
        </div>
      </div>
    </article>
  );
}

TXUCard.propTypes = {
  plan: PropTypes.shape({}).isRequired,
  site: PropTypes.string.isRequired,
  onOrderOnline: PropTypes.func.isRequired,
  disableCartLinks: PropTypes.bool,
  dwellingType: PropTypes.string.isRequired,
};

TXUCard.defaultProps = {
  disableCartLinks: false,
};

export default TXUCard;
