import React, { useContext } from 'react';
import { Sites } from '@rvpower/constants';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import SiteContext from '../SiteContext';

function CardDetailsTable(props) {
  const { plan, isROG = false } = props;
  const {
    prices,
    estimates,
    unitOfMeasure: { currency, description }
  } = plan;

  const { rules } = useMonarch();
  const monarchCoreLogic = rules?.source?.gridCoreLogicSort?.value;

  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const clBuildingSQFT = Number(localStorage.getItem('clBuildingSQFT'));
  const estimatedKWHUsage = Number(localStorage.getItem('estimatedKWHUsage'));
  const usages = [500, 1000, 2000];

  const usagesToDisplay = () => ((coreLogicPreamp || monarchCoreLogic) && estimatedKWHUsage
    ? [estimatedKWHUsage, ...usages]
    : usages
  );

  const estimatedPricePerKWH = plan?.clCalculatedData?.estimatedPricePerKWH;
  const clEstimatedCharge = estimatedPricePerKWH ? (estimatedPricePerKWH * estimatedKWHUsage).toFixed(1) : 0;
  const site = useContext(SiteContext);
  const isTXU = site === Sites.TXU;

  const homeTypes = () => {
    const types = [
      {
        type: 'Apartment',
        size: '500-999'
      },
      {
        type: 'Small home',
        size: '1,000-1,999'
      },
      {
        type: 'Large home',
        size: '2,000+'
      },
    ];
    const yourHomeType = { type: 'Your home', size: clBuildingSQFT };
    const displayCoreLogicTypes = (coreLogicPreamp || monarchCoreLogic) && estimatedKWHUsage && clBuildingSQFT;

    return displayCoreLogicTypes ? [yourHomeType, ...types] : types;
  };

  const homeSizes = homeTypes().map((homeType) => {
    const clColumn = homeType.type === 'Your home';

    return (
      <th
        key={homeType.type}
        className={`plan-details__table-header-cell ${clColumn ? 'grid-v2__prefill-table-header' : ''}`}
      >
        <strong>{homeType.type}</strong>
        <div className="plan-details__table-header-size">
          <span className="plan-details__table-header-size-label">{homeType.size}</span>
          <span>sq. ft</span>
        </div>
      </th>
    );
  });

  const monthlyUsages = usagesToDisplay().map((usage, index) => {
    const clColumn = usagesToDisplay().length === 4 && index === 0;

    return (
      <td
        key={usage}
        className={`plan-details__table-cell cell ${clColumn ? 'grid-v2__prefill-table-cell' : ''}`}
      >
        {`${usage} ${description || 'kWh'}`}
      </td>
    );
  });

  const estimatedCharges = usagesToDisplay().map((usage, index) => {
    const clColumn = usagesToDisplay().length === 4 && index === 0;
    const charge = estimates[usage] || clEstimatedCharge;
    const roundedCharge = Math.round(charge);

    return (
      <td
        key={usage}
        className={`plan-details__table-cell cell ${clColumn ? 'grid-v2__prefill-table-cell' : ''}`}
      >
        <span>$</span>
        {roundedCharge}
      </td>
    );
  });

  const averagePrices = usagesToDisplay().map((usage, index) => {
    const clColumn = usagesToDisplay().length === 4 && index === 0;
    const estimatedPrice = (prices[usage] * 100).toFixed(1);
    const clEstimatedPrice = estimatedPricePerKWH ? (estimatedPricePerKWH * 100)?.toFixed(1) : 0;

    return (
      <td
        key={usage}
        className={
          `plan-details__table-cell plan-details__table-cell--last cell
          ${clColumn ? 'grid-v2__prefill-table-cell' : ''}`
        }
      >
        {prices[usage] ? estimatedPrice : clEstimatedPrice}
        <span>{currency || '¢'}</span>
      </td>
    );
  });

  return (
    <table>
      <tbody className="table u-display-flex u-flex-direction-column">
        {!isROG && (
          <tr className="row u-display-flex u-flex-direction-row">
            {/* eslint-disable-next-line max-len */}
            <td className="plan-details__table-cell-label cell plan-details__first-table-cell">
              Home Sizes
            </td>
            {homeSizes}
          </tr>
        )}
        <tr className="row u-display-flex u-flex-direction-row">
          <td className="plan-details__table-cell-label cell">
            <strong>Usage amount</strong>
          </td>
          {monthlyUsages}
        </tr>
        {!isTXU && (
          <tr className="row u-display-flex u-flex-direction-row">
            <td className="plan-details__table-cell-label cell">
              <strong>Monthly bill estimate*</strong>
            </td>
            {estimatedCharges}
          </tr>
        )}
        <tr className="row u-display-flex u-flex-direction-row">
          <td className="plan-details__table-cell-label cell">
            <strong>{`Average price per ${description || 'kWh'}`}</strong>
          </td>
          {averagePrices}
        </tr>
      </tbody>
    </table>
  );
}

export default CardDetailsTable;
