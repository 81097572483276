import React, { useState, useEffect } from 'react';
import { ProductListViewed, ProductListFiltered } from '@rvpower/track';
import {
  DefaultFilters,
  GridSortOptions,
  GridNewSOESortOptions,
  termTypeToText,
  Sites,
  SupplierIds,
  RatingSites,
  supplierIdMap,
  cartUrl as buildCartUrl,
  cartV3Url as buildCartV3Url,
} from '@rvpower/constants';
import URLSearchParams from '@ungap/url-search-params';
import { isMobile } from 'react-device-detect';
import isEqual from 'lodash.isequal';
import tracking from '../../js/project/tagular/tracking';

import { getAllPlanList, getFilterFields } from '../../js/project/tracking-helper';
import getDefaultFeaturedPlans from '../../js/project/featured-plans-defaults';
import planFilterFn from '../../js/project/plan-filter';
import { usePreamp } from '../preamp';
import { useMonarch } from '../monarch';
import { returnComponent } from '../monarch/NTXSolarCard';

// React Components
import UnservePage from '../unserve/UnservePage';
import FeaturedCards from './FeaturedCards';
import ZipSearch from './ZipSearch';
import CardsKwh from '../kwh-calculator/CardsKwh';
import ComparisonCards from '../ComparisonCards';
import ComparisonBanner from '../ComparisonBanner';
import Disclaimer from '../Disclaimer';
import GuaranteeBanner from '../GuaranteeBanner';
import GridComparisonTool from '../grid-comparison-tool/GridComparisonTool';
import Accordion from '../grid-faqs/Accordion';
import GraphCard from '../electric-phoenix/graph-card';
import UtilitySelectModal from '../UtilitySelectModal';
import ChooseGridBannerArcadia from '../choose-grid-banner/ChooseGridBannerArcadia';
import NonGimmickyBanner, { config as NonGimmickyBannerConfig } from '../NonGimmickyBanner';
import RateBanner from './RateBanner';
import EmailCapture from './EmailCapture';
import SortButton from './SortButton';
import PrefillBanner from './PrefillBanner';
import EsiidAddressLookup from './EsiidAddressLookup';
import SplashHero from '../splash-page/SplashHero';
import ZipSuccessBanner from '../ZipSuccessBanner';
import ComparePriceBanner from '../ComparePriceBanner';
import SocialProofBanner from '../SocialProofBanner';
import SavingsBanner from '../SavingsBanner';
import CommunitySolarBanner from '../monarch/CommunitySolarBanner';
import HmcSuccessBanner from '../help-me-choose/HmcSuccessBanner';
import BegEntryBanner from '../monarch/BegEntryBanner';

// New Filter Sections
import FilterMenuMobile from './filter-menu/FilterMenuMobile';
import Pills from './pills';
import NoPlanResults from './no-plan-results';

const usageLegend = {
  TwoThousandKwh: 2000,
  OneThousandKwh: 1000,
  FiveHundredKwh: 500
};

// pass over mover-switcher and dwelling-type to txu cartUrl
const params = new URLSearchParams(window.location.search);
let moverSwitcher = '';

if (params.get('m') === 'moven') {
  moverSwitcher = 'switch';
} else if (params.get('m') === 'movey') {
  moverSwitcher = 'mover';
}

let dwellingType = '';
if (params.get('a') === 'apartmenty') {
  dwellingType = 'multi';
} else if (params.get('a') === 'apartmentn') {
  dwellingType = 'single';
}

let showGreenPlans = false;
if (params.get('showGreenPlans') === 'Yes') {
  showGreenPlans = true;
}

function getFeaturedPlans(plansById, utilityId, featuredConfig, site) {
  const { utilities } = featuredConfig;

  if (utilities) {
    const { featuredPlans = [] } = utilities.find((util) => util.utilityId === utilityId) || {};
    return featuredPlans.map((plan) => (
      {
        ...plansById[plan.planId],
        labelId: parseInt(plan.labelId, 10),
        usageId: plan.usageId,
        distinction: plan.distinction
      }
    )).filter((plan) => plan.name);
  }
  return getDefaultFeaturedPlans(site).map((plan) => ({ ...plansById[plan.planId], labelId: plan.labelId }))
    .filter((plan) => plan.name);
}

function getDefaultFilters(listConfig) {
  const { filters = {} } = listConfig;
  const {
    usage, features, providers, rateTypes, terms, customPlanOrder
  } = filters;

  const usageString = usage;

  return {
    usage: parseInt(usageString, 10) || 1000,
    features: showGreenPlans ? ['GreenEnergy'] : (features || []),
    providers: providers || [],
    rateTypes: rateTypes || [],
    customPlanOrder: customPlanOrder || [],
    terms: terms ? terms.map(termTypeToText) : [],
    ratings: [],
    billPrice: { minBillPrice: null, maxBillPrice: null },
    utilities: []
  };
}

function editPlans(
  plans = [],
  zipCode,
  filters,
  utility,
  isCallCenterOpen,
  site,
  disablePhoneNumbers,
  isNTX,
  cartV3 = false
) {
  return plans.map((plan) => {
    const prices = {};
    const estimates = {};
    plan.rates.forEach((r) => {
      if (usageLegend[r.type]) {
        prices[usageLegend[r.type]] = r.price ? (parseFloat(r.price)) : 0;
        estimates[usageLegend[r.type]] = r.price ? (parseFloat(r.price) * usageLegend[r.type]).toFixed(0) : 0;
      }
    });

    // We need to add the "SatisfactionGuarantee" to the plan.features array
    // so we can filter for it in the new Filters
    // the data is included within the Harbinger badge data
    const updateFeatures = () => {
      const modifiedFeatures = plan?.features;

      const hasSatisfactionBadge = plan?.badges?.length > 0
        && plan?.badges?.map((badge) => {
          if (badge?.name.includes('satisfaction')) {
            return true;
          }
          return false;
        });

      const includesSatisfaction = hasSatisfactionBadge?.length > 0 && hasSatisfactionBadge?.includes(true);

      if (includesSatisfaction) {
        modifiedFeatures.push('SatisfactionGuarantee');
      }

      return [...new Set(modifiedFeatures)];
    };

    const { usage } = filters;

    // eslint-disable-next-line max-len
    const lubbockZips = ['79364', '79401', '79403', '79404', '79406', '79407', '79410', '79411', '79412', '79413', '79414', '79415', '79416', '79423', '79424'];

    const isLubbock = lubbockZips.includes(zipCode);

    const cartUrl = cartV3 && !isLubbock
      ? buildCartV3Url(plan.id, utility.id, usage, moverSwitcher)
      : buildCartUrl(site, plan.id, utility.id, zipCode, usage, moverSwitcher, dwellingType);

    const disableAllPhoneNumbers = disablePhoneNumbers || plan.supplier.controls?.disablePhoneNumbers;

    return {
      ...plan,
      TermsDoc: plan.documents.find((document) => document.type === 'Terms'),
      EFLDoc: plan.documents.find((document) => document.type === 'Efl'),
      YRACDoc: plan.documents.find((document) => document.type === 'Yrac'),
      fee: plan.fees?.find((fee) => fee.type === 'EarlyTerminationFee'),
      price: prices[usage],
      prices,
      estimate: estimates[usage],
      estimates,
      usage,
      utility,
      cartUrl,
      active: plan.active && !plan.supplier.controls?.disableGrid,
      phoneActive: isCallCenterOpen && !disableAllPhoneNumbers,
      cartActive: plan.cartActive,
      badges: plan.badges,
      termText: termTypeToText(plan.term),
      isNTX,
      features: updateFeatures()
    };
  });
}

function GridNew(props) {
  const {
    ESIID,
    site,
    isCallCenterOpen,
    fuseData,
    disableCartLinks,
    disablePhoneNumbers,
    utilities = [],
    zipCode,
    featuredConfig = {},
    listConfig = {},
    electricPhoenixInput,
    selectedUtility,
    setSelectedUtility,
    utility
  } = props;
  const isTXU = site === Sites.TXU;
  const isSOE = site === Sites.SOE;
  const isChoose = site === Sites.CHOOSE;
  const isCTXP = site === Sites.NEW_CTXP;
  const SOEesiid = isSOE ? ESIID : '';

  const { rules } = useMonarch();

  function epDataValid(obj) {
    let isValid = true;
    if (!obj.annualCost || !obj.annualUsage || !obj.averageCost || !obj.monthlyUsage.length) {
      isValid = false;
    }
    return isValid;
  }

  const [sort, setSort] = useState(null);
  const [hasDefaultSort, setHasDefaultSort] = useState(true);
  const [toggleFilter, setToggleFilter] = useState(false);
  const [kwhOrSqft, setKwhOrSqft] = useState('kwh');
  const [showHelpMeChooseCTA, setShowHelpMeChooseCTA] = useState(false);

  const handleSort = (sortType) => {
    setSort(sortType);
    setHasDefaultSort(false);
  };

  // state that sets the highlighted section on FilterMenuMobile from mobile Pills
  const [filtersActiveSection, setFiltersActiveSection] = useState('');

  const {
    plans = [],
    comparisonPlans = [],
    epData = {},
    electricPhoenixData = {}
  } = utility;

  const originalFilters = getDefaultFilters(listConfig);
  const [filters, setFilters] = useState(originalFilters);
  const gridComparisonToolActive = usePreamp('Grid Comparison Tool');
  const chooseGridBannerArcadia = usePreamp('Choose Grid Banner Arcadia');
  const nonGimmickyBannerAsset = usePreamp(NonGimmickyBannerConfig.placement);
  const faqsBannerActive = !isTXU;
  const [comparedPlans, setComparedPlans] = useState([]);
  const [enableCardAnimation, setEnableCardAnimation] = useState(false);
  /* eslint-disable-next-line no-unused-vars */
  const [kWhAmountUpdated, setKwHamountUpdated] = useState(0);
  const [kWhPlansWithPrice, setKhWPlansWithPrice] = useState([]);
  const [paginationUpdated, setPaginationUpdated] = useState(0);
  const [hmcData, setHmcData] = useState(null);
  const [hmcBannerToggle, setHmcBannerToggle] = useState(false);

  const chooseGridBannerArcadiaActive = site === Sites.CHOOSE && chooseGridBannerArcadia;
  const nonGimmickyBannerActive = site === Sites.NEW_TXER && nonGimmickyBannerAsset;

  const electricPhoenixToggleActive = params.get('electricPhoenixToggleActive') === 'true';
  // eslint-disable-next-line max-len
  const [electricPhoenixActive, setElectricPhoenixActive] = useState(params.get('electricPhoenixActive') === 'true' && epDataValid(electricPhoenixData));
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const ratingsEnabled = RatingSites.includes(site);

  const isNTX = params.get('isNTX') === 'true' && site === Sites.CHOOSE;
  const [ntxBannerProps, setNTXBannerProps] = useState({});
  const [showPriceComparisonBanner, setShowPriceComparisonBanner] = useState(false);
  const [showCommunitySolarBanner, setShowCommunitySolarBanner] = useState(false);
  const [ntxSolarCardResults, setNTXSolarCardResults] = useState({});

  const monarchSimplifiedFilter = rules?.source?.simplifiedFilter?.value;
  // Toggle Cart 3.0
  const monarchCart3Active = rules?.source?.gridCart3Cta?.value || false;

  // Update main ruleset by site
  const siteRuleset = site === Sites.CHOOSE ? 'gridGlobalRules' : 'globalTrafficFlows';

  const getMonarchResults = (payload) => {
    // Only use this Monarch call if the rules need to be coming from a ruleset.
    // Currently, only Choose needs this as they make decisions based on selected utility
    // and can't make that evaluation on initial page load as a global rule.
    window.monarch('ruleset', siteRuleset, payload, {}, (err, result) => {
      if (err) console.error('Monarch Error', err);
      if (Object.keys(result).length > 0) {
        setShowHelpMeChooseCTA(result?.gridHelpMeChoose || false);

        // Handling the community and PTC banners. Should not show at the same time
        const ntxBannerData = result?.ntxCommunityPtcBanner?.data;
        if (ntxBannerData) {
          setShowCommunitySolarBanner(ntxBannerData.bannerType?.toLowerCase() === 'community');
          setShowPriceComparisonBanner(ntxBannerData.bannerType?.toLowerCase() === 'ptc');
          setNTXBannerProps(ntxBannerData);
        }

        // Handle NTX Solar Card return
        if (Object.keys(result.nTX_Solar_Card).length > 0) setNTXSolarCardResults(result.nTX_Solar_Card);
      } else {
        setShowPriceComparisonBanner(false);
      }
    });
  };

  // Use Effect for supplying custom payload to Monarch
  useEffect(() => {
    if (window.cohesion && utility.name) {
      const monarchPayload = {
        ntxUtility: utility.name,
      };
      window.cohesion('monarch:ready', () => {
        getMonarchResults(monarchPayload);
      });
    }
  }, [utility.name]);

  useEffect(() => {
    if (ntxSolarCardResults) returnComponent(ntxSolarCardResults);
  }, [ntxSolarCardResults.componentName]);

  const { usage } = filters;

  const newDefaultFilters = JSON.parse(JSON.stringify(DefaultFilters));
  const newFilters = JSON.parse(JSON.stringify(filters));
  delete newDefaultFilters.usage;
  delete newFilters.usage;
  const hasDefaultFilters = isEqual(DefaultFilters, filters);
  const newHasDefaultFilters = isEqual(newDefaultFilters, newFilters);
  const hasDefaultUsage = originalFilters.usage === usage;

  const defaultSortAndFilters = hasDefaultSort && hasDefaultFilters;
  const showFeaturedCards = hasDefaultSort && newHasDefaultFilters && hasDefaultUsage && paginationUpdated === 0;

  useEffect(() => {
    if (hasDefaultFilters) {
      setHmcData(null);
    }
  }, [hasDefaultFilters]);

  let RateBannerActive = usePreamp('Grid Reskin - Rate Banner') || false;
  let {
    rateBannerTitle = null,
    rateBannerDescription = null
  } = usePreamp('Grid Reskin - Rate Banner', true) || {};

  const monarchRateBanner = rules?.source?.gridRateBanner?.value;

  if (monarchRateBanner?.rateBannerTitle) {
    RateBannerActive = true;
    rateBannerTitle = monarchRateBanner.rateBannerTitle;
    rateBannerDescription = monarchRateBanner.rateBannerDescription;
  }

  const preampSeasonalData = usePreamp('Grid Reskin - Seasonal Messaging', true);
  const monarchSeasonalData = rules?.source?.gridReskinSeasonalMessaging?.value?.data;
  const seasonalBannerData = preampSeasonalData || monarchSeasonalData || {};

  const SOEEmailCaptureActive = usePreamp('Grid Reskin - Email Capture') || false;
  const coreLogicPreamp = usePreamp('Grid - Core Logic Sort') || false;
  const coreLoginMonarch = rules?.source?.gridCoreLogicSort?.value;
  const coreLogicBool = coreLogicPreamp || coreLoginMonarch;
  const estimatedKWHUsage = Number(localStorage.getItem('estimatedKWHUsage'));
  const [bannerOpen, setBannerOpen] = useState(true);
  const preampCart3Active = usePreamp('Cart V3 - CTA') || false;

  const monarchSocialBannerData = rules?.source?.gridSocialProofingBanner?.value?.data;
  const socialBanner = monarchSocialBannerData || {};
  const monarchSavingsBanner = rules?.source?.gridSavingsBanner?.value;
  const monarchBEGGridEntry = rules?.source?.begGridEntryPoint?.value;

  useEffect(() => {
    document.documentElement.scrollIntoView();

    const scrollY = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;

    if (scrollY > 0) {
      window.scrollTo(0, 0);
    }
  }, []);
  useEffect(() => {
    if (electricPhoenixActive) {
      setSort('bill-low');
    } else if (coreLogicBool && estimatedKWHUsage) {
      setSort('recommended');
    } else {
      if (!listConfig.filters) { return; }
      setSort(listConfig.sort || 'rate-low');
      setFilters(getDefaultFilters(listConfig));
    }
  }, [listConfig, electricPhoenixActive]);

  const editedComparisonPlans = editPlans(
    comparisonPlans,
    zipCode,
    filters,
    utility,
    isCallCenterOpen,
    site,
    disablePhoneNumbers,
    isNTX
  );

  const shouldAbortLoadingPlans = (txuPlans) => {
    if (!isTXU) {
      return false;
    }

    return txuPlans.some((plan) => plan.supplier.id !== SupplierIds.TxuBranded);
  };

  /** Recursively update plans with their updated pricing */
  const setKwhPlansWithPrices = (plansWithPrice) => {
    const newPlanPrice = plansWithPrice[0]?.price;
    const oldPlanPrice = kWhPlansWithPrice[0]?.price;
    if (newPlanPrice !== oldPlanPrice) {
      setKhWPlansWithPrice([...plansWithPrice]);
    }
  };

  /** Check EP data object to ensure all properties have a value */
  const checkEPObject = (data) => {
    const properties = Object.entries(data);
    // eslint-disable-next-line
    return properties.map((property) => {
      const value = property[1];
      if (typeof value === 'string' && !value) {
        return false;
      }

      if (typeof value === 'number' && value <= 0) {
        return false;
      }

      if (Array.isArray(value) && !value.length) {
        return false;
      }

      if (typeof value === 'object') {
        return Object.keys.length > 0;
      }

      return true;
    }).every((v) => v === true);
  };

  const cart3Active = preampCart3Active || monarchCart3Active;

  const editedPlans = editPlans(
    plans,
    zipCode,
    filters,
    utility,
    isCallCenterOpen,
    site,
    disablePhoneNumbers,
    isNTX,
    cart3Active
  )
    .filter((p) => {
      let epActiveAndPlanIneligible = false;
      if (electricPhoenixActive) {
        // Check is plan is valid for electric phoenix
        if (!p.electricPhoenixActive || !checkEPObject(p.electricPhoenixData)) {
          epActiveAndPlanIneligible = true;
        }
        const supplierName = epData.input.lastBillSupplierName;
        // Remove all plans whose provider matches the electric phoenix questionnaire current provider response
        if (p.supplier.id === supplierIdMap[supplierName]) {
          epActiveAndPlanIneligible = true;
        }
      }
      return p.active && (p.phoneActive || p.cartActive) && !p.hideOnGrid && !epActiveAndPlanIneligible;
    });

  const editedPlansById = editedPlans.reduce((accum, curVal) => ({ ...accum, [curVal.id]: curVal }), {});
  const featuredPlans = getFeaturedPlans(editedPlansById, utility.id, featuredConfig, site);

  useEffect(() => {
    if (utilities.length === 1 || (utilities.length > 1 && selectedUtility)) {
      ProductListViewed(getAllPlanList(featuredPlans, editedPlans), zipCode);
    }
  }, [selectedUtility, utilities.length, zipCode]);

  // filter options based off plans
  const terms = [...new Set(editedPlans.map((p) => p.termText))];
  const providersById = editedPlans.reduce((accum, p) => {
    const { id, name } = p.supplier;
    if (!accum[id]) return { ...accum, [id]: { id, name } };
    return accum;
  }, {});

  const providers = Object.values(providersById).sort((a, b) => a.name.localeCompare(b.name));
  const rates = [...new Set(editedPlans.map((p) => p.type))].filter((el) => el != null);

  // remove any filters that don't have any plans associated with them
  if (filters.terms) filters.terms = filters.terms.filter((t) => terms.includes(t));
  if (filters.providers) filters.providers = filters.providers.filter((p) => !!providersById[p]);
  if (filters.rates) filters.rates = filters.rates.filter((r) => rates.includes(r));

  // filter the plans
  const filteredPlans = editedPlans.filter(planFilterFn(filters, ratingsEnabled));

  const [epPrimaryPlan, setEpPrimaryPlan] = useState(filteredPlans[0]);

  const updatePlanPrice = (planList) => {
    const plansWithPrice = planList.map((plan) => {
      const pricePerKwhMonth = plan?.price || [];
      if (!pricePerKwhMonth) {
        return null;
      }

      const roundedEstimate = Math.round(pricePerKwhMonth * usage);
      const roundedPricePerKwh = (pricePerKwhMonth * 100).toFixed(1);
      // eslint-disable-next-line no-param-reassign
      plan.roundedEstimate = roundedEstimate;
      // eslint-disable-next-line no-param-reassign
      plan.roundedPricePerKwh = roundedPricePerKwh;
      return plan;
    });
    return plansWithPrice;
  };

  useEffect(() => {
    if (kWhAmountUpdated) {
      const updatedPlanValues = kWhPlansWithPrice?.filter(
        (plan) => comparedPlans.some((planToMatch) => plan.name === planToMatch.name)
      );
      const latestWithPrice = updatePlanPrice(updatedPlanValues);
      setComparedPlans([...latestWithPrice]);
    }

    if (coreLogicBool) {
      setBannerOpen(true);
    }
  }, [kWhPlansWithPrice]);

  const updateFilters = (f) => {
    setFilters(f);
    setKwHamountUpdated(f.usage);
    ProductListFiltered(
      getAllPlanList(featuredPlans, editedPlans),
      getFilterFields(f, providersById), GridSortOptions[sort]
    );
  };

  useEffect(() => {
    if (showGreenPlans) updateFilters(originalFilters);

    // Prefill the Kwh usage filter when core logic data is available
    if (coreLogicBool && estimatedKWHUsage) {
      updateFilters({ ...filters, usage: estimatedKWHUsage });
      setKwHamountUpdated(estimatedKWHUsage);
    }
  }, []);

  const updateHmcData = (data) => {
    setHmcData(data);
  };

  useEffect(() => {
    if (hmcData && hmcData?.estimatedUsage !== null) {
      updateFilters({ ...filters, usage: hmcData?.estimatedUsage });
      setKwHamountUpdated(hmcData?.estimatedUsage);
      setHmcBannerToggle(true);
    }
  }, [hmcData]);

  const updateHmcBannerToggle = (toggle) => {
    setHmcBannerToggle(toggle);
  };

  function generateQuestionnaireUrlParams() {
    const zipcodeValue = params.get('zipcode');
    let movingParam = '';
    if (params.get('m')) {
      if (params.get('m') === 'movey') {
        movingParam = 'm=movey';
      } else {
        movingParam = 'm=moven';
      }
    }

    return `?zipcode=${zipcodeValue}${movingParam && '&'}${movingParam && movingParam}&option=true`;
  }

  const toggleEP = () => {
    tracking.elementClicked({
      webElement: {
        elementType: 'Button',
        location: electricPhoenixActive ? 'EP Grid' : 'Control Grid',
        name: electricPhoenixActive ? 'Toggle to control grid' : 'Toggle to EP grid',
        text: 'Load page to show plans with highest savings first',
        position: 'Grid',
      }
    });

    // If questionnaire was not filled out, redirect back to questionnaire page
    if (!electricPhoenixActive && (!epData.annualCost || !epData.annualUsage || !epData.averageCost)) {
      document.location.href = `/home-info${generateQuestionnaireUrlParams()}`;
    }
    setElectricPhoenixActive(!electricPhoenixActive);
  };

  const getPlansCount = (currentSite) => {
    if ([Sites.TXU].includes(currentSite)) {
      return filteredPlans.length;
    }

    return editedPlans.length;
  };

  if (getPlansCount(site) === 0 && utilities.length === 0 && (isSOE || isChoose)) {
    return (
      <UnservePage
        zipCode={zipCode}
        site={site}
        plansCount={getPlansCount(site)}
      />
    );
  }

  // We will only display NTX Featured Cards if there are at least 5 plans available for the utility
  const showNTXFCs = isNTX && (getPlansCount(site) > 4);
  const currentURL = window.location.href;
  const isSplashPage = currentURL.includes('/sp/');
  const zipEntered = localStorage.getItem('splashZipEntered');
  const splashDeleteClass = isCTXP && isSplashPage && !zipEntered ? 'splash-delete' : '';

  if (isCTXP && isSplashPage) {
    const filterContainer = document.querySelector('.grid-v2-pills');

    if (filterContainer) {
      filterContainer.classList.add('pill-container');
    }
  }
  return (
    <div className="grid-v2">
      {monarchSavingsBanner?.bannerText && !isNTX && (
        <SavingsBanner bannerText={monarchSavingsBanner.bannerText} />
      )}
      {isCTXP && isSplashPage && !zipEntered && (
        <SplashHero
          zipCode={zipCode}
          isCTXP={isCTXP}
          isSplashPage={isSplashPage}
        />
      )}
      {isCTXP && isSplashPage && zipEntered && <ZipSuccessBanner />}
      <div className={`grid-v2__column ${splashDeleteClass}`}>
        {coreLogicBool && bannerOpen && estimatedKWHUsage > 0 && (
          <PrefillBanner
            filters={filters}
            setBannerOpen={setBannerOpen}
            setFilters={updateFilters}
          />
        )}
      </div>
      <div className={`${isNTX ? 'grid-v2-ntx' : ''}`}>
        <div className={`grid-v2__column ${splashDeleteClass}`}>
          {!monarchSimplifiedFilter
            && (coreLogicBool && estimatedKWHUsage ? (
              <EsiidAddressLookup plansCount={getPlansCount(site)} />
            ) : (
              <ZipSearch
                plansCount={getPlansCount(site)}
                zipCode={zipCode}
                site={site}
                isNTX={isNTX}
                isSplashPage={isSplashPage}
              />
            ))}
        </div>
        <Pills
          allPlans={editedPlans}
          isCallCenterOpen={isCallCenterOpen}
          fuseData={fuseData}
          filters={filters}
          isOpen={toggleFilter}
          isNTX={isNTX}
          plans={filteredPlans}
          providers={providers}
          rates={rates}
          setIsOpen={setToggleFilter}
          setFilters={updateFilters}
          setFiltersActiveSection={setFiltersActiveSection}
          setUsage={setKwhOrSqft}
          setSort={handleSort}
          terms={terms}
          setSelectedUtility={setSelectedUtility}
          utilities={utilities}
          utility={utility}
          usage={kwhOrSqft}
          site={site}
          splashDeleteClass={splashDeleteClass}
          isSplashPage={isSplashPage}
          showHelpMeChooseCTA={showHelpMeChooseCTA}
          hmcData={hmcData}
          updateHmcData={updateHmcData}
          getPlansCount={getPlansCount}
          zipCode={zipCode}
          monarchSimplifiedFilter={monarchSimplifiedFilter}
        />
      </div>
      <div className="grid-v2__column grid-v2__column-extended">
        <FilterMenuMobile
          allPlans={editedPlans}
          filters={filters}
          filtersActiveSection={filtersActiveSection}
          isNTX={isNTX}
          isOpen={toggleFilter}
          options={GridNewSOESortOptions}
          plans={filteredPlans}
          providers={providers}
          rates={rates}
          setIsOpen={setToggleFilter}
          setFilters={updateFilters}
          setFiltersActiveSection={setFiltersActiveSection}
          setSelectedUtility={setSelectedUtility}
          setSort={handleSort}
          setUsage={setKwhOrSqft}
          sort={sort}
          terms={terms}
          utilities={utilities}
          utility={utility}
          site={site}
        />
      </div>
      {hmcBannerToggle && !hasDefaultFilters && hmcData !== null && (
        <HmcSuccessBanner
          updateHmcBannerToggle={updateHmcBannerToggle}
          hmcData={hmcData}
        />
      )}
      {RateBannerActive && !coreLogicBool && (
        <div className={`grid-v2__column ${splashDeleteClass}`}>
          <RateBanner
            title={rateBannerTitle}
            description={rateBannerDescription}
            fuseData={fuseData}
            site={site}
          />
        </div>
      )}
      {isCTXP && socialBanner?.bannerPlacement === 'above' && (
        <SocialProofBanner socialBanner={socialBanner} />
      )}
      {electricPhoenixActive && filteredPlans.length && (
        <GraphCard
          plan={epPrimaryPlan}
          electricPhoenixToggleActive={electricPhoenixToggleActive}
          toggleEP={toggleEP}
          epData={electricPhoenixData}
          site={site}
          electricPhoenixInput={electricPhoenixInput}
        />
      )}
      {nonGimmickyBannerActive && (
        <NonGimmickyBanner assetName={nonGimmickyBannerAsset} />
      )}
      {isNTX && showPriceComparisonBanner && (
        <ComparePriceBanner
          title={ntxBannerProps?.title}
          content={ntxBannerProps?.content}
          protip={ntxBannerProps?.protip}
        />
      )}
      {isNTX && showCommunitySolarBanner && (
        <CommunitySolarBanner
          title={ntxBannerProps?.title}
          subtitle={ntxBannerProps?.subtitle}
          ctaText={ntxBannerProps?.ctaText}
        />
      )}
      {(isNTX ? showNTXFCs : true)
        && !electricPhoenixActive
        && showFeaturedCards
        && filteredPlans?.length > 0 && (
          <FeaturedCards
            featuredPlans={featuredPlans}
            site={site}
            shouldAbortLoadingPlans={shouldAbortLoadingPlans}
            gridComparisonToolActive={gridComparisonToolActive}
            comparedPlans={comparedPlans}
            setComparedPlans={setComparedPlans}
            disableCartLinks={disableCartLinks}
            electricPhoenixToggleActive={electricPhoenixToggleActive}
            toggleEP={toggleEP}
            dwellingType={dwellingType}
            newGridEnabled
            sort={sort}
            setSort={handleSort}
            options={GridNewSOESortOptions}
            usage={usage}
            utility={utility?.id}
            isNTX={isNTX}
            isSplashPage={isSplashPage}
            zipEntered={zipEntered}
            zipCode={zipCode}
          />
      )}
      {monarchBEGGridEntry?.title && <BegEntryBanner site={site} bannerContent={monarchBEGGridEntry} />}
      {chooseGridBannerArcadiaActive && (
        <ChooseGridBannerArcadia assetName={chooseGridBannerArcadia} />
      )}
      {gridComparisonToolActive && !isTXU && !isNTX && <ComparisonBanner />}
      {isTXU && !isMobile && <GuaranteeBanner location="middle" />}
      {gridComparisonToolActive && !isTXU && (
        <GridComparisonTool
          comparedPlans={comparedPlans}
          setComparedPlans={setComparedPlans}
          site={site}
          usage={usage}
        />
      )}
      {filteredPlans?.length === 0 && (
        <NoPlanResults site={site} isNTX={isNTX} />
      )}
      <div className={`grid-v2__column ${isNTX ? 'ntx' : ''}`}>
        {filteredPlans?.length > 0 && (
          <>
            {isCTXP && socialBanner?.bannerPlacement === 'below' && (
              <SocialProofBanner socialBanner={socialBanner} />
            )}
            {SOEEmailCaptureActive && defaultSortAndFilters && <EmailCapture />}
            <div className="grid-v2__cards-title-container">
              <p className="grid-v2__cards-title">
                {!defaultSortAndFilters && !coreLogicBool && !isNTX
                  ? `${filteredPlans.length} plans`
                  : 'All other plans'}
              </p>
              <SortButton
                options={GridNewSOESortOptions}
                sort={sort}
                setSort={setSort}
                isNTX={isNTX}
                showHelpMeChooseCTA={showHelpMeChooseCTA}
              />
            </div>
            <CardsKwh
              setPaginationUpdated={(paginationStartIndex) => setPaginationUpdated(paginationStartIndex)}
              seasonalBannerData={seasonalBannerData}
              ESIID={SOEesiid}
              electricPhoenixActive={electricPhoenixActive}
              electricPhoenixToggleActive={electricPhoenixToggleActive}
              epPrimaryPlan={epPrimaryPlan}
              setEpPrimaryPlan={setEpPrimaryPlan}
              electricPhoenixInput={electricPhoenixInput}
              shouldAbortLoadingPlans={shouldAbortLoadingPlans}
              plans={filteredPlans}
              site={site}
              usage={usage}
              sort={sort}
              epData={electricPhoenixData}
              filters={filters}
              utility={utility?.id}
              enableCardAnimation={enableCardAnimation}
              setEnableCardAnimation={setEnableCardAnimation}
              comparedPlans={comparedPlans}
              setComparedPlans={setComparedPlans}
              gridComparisonToolActive={gridComparisonToolActive}
              setKwhPlansWithPrices={setKwhPlansWithPrices}
              kWhAmountUpdated={kWhAmountUpdated}
              faqsBannerActive={faqsBannerActive}
              disableCartLinks={disableCartLinks}
              isNTX={isNTX}
              newGridEnabled
              hmcData={hmcData}
              hasDefaultFilters={hasDefaultFilters}
            />
            {editedComparisonPlans.length > 0 && (
              <ComparisonCards plans={editedComparisonPlans} />
            )}
            <Disclaimer
              site={site}
              electricPhoenixActive={electricPhoenixActive}
              ntxUtilityName={utility?.name}
            />
          </>
        )}
        {!isNTX && <Accordion site={site} />}
        <UtilitySelectModal
          selectedUtility={utility}
          utilities={utilities}
          setSelectedUtility={setSelectedUtility}
          isNTX={isNTX}
        />
      </div>
    </div>
  );
}

export default GridNew;
