const {
  SupplierIds,
  ParentPartnerIds,
  UtilityMap,
  UtilityNames
} = require('./id');
const { ProcessErrors } = require('./error');
const {
  Sites, Urls, cartUrl, cartV3Url, gridUrl, siteCta, siteLegal,
} = require('./url');
const {
  EPQ_DATA,
  assembleEPInput,
  supplierIdMap
} = require('./ep');

// specifically used on GridNew SOE layout
const DefaultFilters = {
  billPrice: {
    minBillPrice: null,
    maxBillPrice: null
  },
  customPlanOrder: [],
  features: [],
  providers: [],
  rateTypes: [],
  ratings: [],
  terms: [],
  usage: 1000,
  utilities: []
};

const GridSortOptions = {
  'rate-low': 'Rate Low to High',
  'rate-high': 'Rate High to Low',
  'terms-longer': 'Terms Longer to Shorter',
  'terms-shorter': 'Terms Shorter to Longer',
  'provider-a': 'Provider A-Z',
  'provider-z': 'Provider Z-A',
  recommended: 'Recommended'
};

const GridSortOptionsEP = {
  'bill-low': 'Average Bill Low to High',
  'bill-high': 'Average Bill High to Low',
  'terms-longer': 'Terms Longer to Shorter',
  'terms-shorter': 'Terms Shorter to Longer',
  'provider-a': 'Provider A-Z',
  'provider-z': 'Provider Z-A',
  recommended: 'Recommended'
};

const TxuGridSortOptions = {
  'rate-low': 'Rate Low to High',
  'rate-high': 'Rate High to Low',
  'terms-longer': 'Terms Longer to Shorter',
  'terms-shorter': 'Terms Shorter to Longer',
  recommended: 'Recommended'
};

const GridNewSOESortOptions = {
  recommended: 'Recommended',
  'rate-low': 'Rate: low to high',
  'rate-high': 'Rate: high to low',
  'monthly-bill-low': 'Monthly bill: low to high',
  'monthly-bill-high': 'Monthly bill: high to low',
  'terms-longer': 'Term: longer to shorter',
  'terms-shorter': 'Term: shorter to longer',
  'provider-a': 'Provider: A to Z',
  'provider-z': 'Provider: Z to A'
};

const FeaturedCardLabels = [
  'Best for apartments', // labelId: 1
  'Best for small houses', // labelId: 2
  'Best for large houses', // labelId: 3
  'All credit approved', // labelId: 4
  'No deposit required', // labelId: 5
  'Go green', // labelId: 6
  'Popular plan', // labelId: 7
  'Great value', // labelId: 8
  'No surprises', // labelId: 9
  'Free nights', // labelId: 10
  'Free weekends', // labelId: 11
  'Bill credits', // labelId: 12
  'Seasonal discounts', // labelId: 13
  'Our mover special', // labelId: 14
  '7 days free', // labelId: 15
  'Automatic savings', // labelId: 16
  'Online rewards', // labelId: 17
  'Cash back', // labelId: 18
  'Visa gift card', // labelId: 19
  'Cash back', // labelId: 20
  'Smart home equipment', // labelId: 21
  'Great for work from home', // labelId: 22
  'Instant approval', // labelId: 23
  'High customer satisfaction', // labelId: 24
  'Popular provider', // labelId: 25
  'Low energy charge', // labelId: 26
  'Popular plan', // labelId: 27
  'Green energy', // labelId: 28
  'Competitive rate', // labelId 29
  'AC protection', // labelId 30
  'Flat energy rate', // labelId 31
  'Longer price guarantee', // labelId 32
  'Longer price security', // labelId 33
  'No gimmicks', // labelId 34
  '100% Renewable - Leaf', // labelId 35
  '100% Renewable - Recycle', // labelId 36
  'Popular for moving', // labelId 37
  'Popular for switching', // labelId 38
  'Exclusive plan', // labelId 39 - Standard
  'Exclusive plan', // labelId 40 - Color
  'Reliable energy plan', // labelId 41
  'Reliable gas plan', // labelId 42
  'Reliable winter plan', // labelId 43
  'Excellent winter plan', // labelId 44
  'Limited time discount', // labelId 45 - Standard
  'Limited time discount', // labelId 46 - Gradient
  'Discounted plan', // labelId 47 - Standard
  'Discounted plan', // labelId 48 - Gradient
  'Act now! Limited time offer', // labelId 49 - Standard
  'Act now! Limited time offer', // labelId 50 - Gradient
].map((o, i) => ({ label: o, value: i + 1 }));

const UsageAmounts = [
  '500',
  '1000',
  '2000',
].map((o, i) => ({ label: o, value: i }));

const RatingSites = [
  Sites.CHOOSE,
  Sites.NEW_TXER,
  Sites.NEW_CTXP,
  Sites.SOE
];

const chameleonWidgetData = {
  [Sites.SOE]: {
    creativeWidgetId: '3810723e-91d9-4b94-9a6f-6248a9cf05be',
    widgetCampaign: 'chameleon-save-on-energy-zip-detected-providers'
  },
  [Sites.NEW_CTXP]: {
    creativeWidgetId: 'd207d65a-aa2e-4c6b-902f-8485c923c903',
    widgetCampaign: 'chameleon-choose-tx-power-zip-detected-providers'
  },
  [Sites.CHOOSE]: {
    creativeWidgetId: '7b102d78-3b4f-442b-806b-3dd32cce6e7f',
    widgetCampaign: 'chameleon-choose-energy-zip-detected-providers'
  },
  [Sites.NEW_TXER]: {
    creativeWidgetId: '971bec51-ab4e-495c-aad0-83822fdad65b',
    widgetCampaign: 'chameleon-tx-electric-rates-zip-detected-providers'
  },
  default: {
    creativeWidgetId: 'd207d65a-aa2e-4c6b-902f-8485c923c903',
    widgetCampaign: 'chameleon-choose-tx-power-zip-detected-providers'
  }
};

const permaleasePivotPhoneNumbers = {
  [Sites.CHOOSE]: {
    credit: {
      text: '8557186161',
      formatted: '1-855-718-6161'
    },
    qualification: {
      text: '8558385573',
      formatted: '1-855-838-5573'
    }
  },
  [Sites.NEW_CTXP]: {
    credit: {
      text: '8442076784',
      formatted: '1-844-207-6784'
    },
    qualification: {
      text: '8338550434',
      formatted: '1-833-855-0434'
    }
  },
  [Sites.NEW_TXER]: {
    credit: {
      text: '8338476947',
      formatted: '1-833-847-6947'
    },
    qualification: {
      text: '8338524238',
      formatted: '1-833-855-0434'
    }
  },
  [Sites.SOE]: {
    credit: {
      text: '8332023839',
      formatted: '1 (833) 202-3839'
    },
    qualification: {
      text: '8777419736',
      formatted: '1 (877) 741-9736'
    }
  },
  default: {
    credit: {
      text: '',
      formatted: ''
    },
    qualification: {
      text: '',
      formatted: ''
    }
  }
};

function termTypeToText(term) {
  if (term.type === 'MonthToMonth') {
    return 'Month to Month';
  } if (term.type === 'Prepaid') {
    return 'Prepaid';
  }
  return `${term.length} Months`;
}

function termTextToType(display) {
  switch (display) {
    case 'Month to Month':
      return { type: 'MonthToMonth' };
    case 'Prepaid':
      return { type: 'Prepaid' };
    default: {
      const match = /[0-9]*/.exec(display);
      return { type: 'FixedMonths', length: match ? match[0] : 0 };
    }
  }
}

function getStateByZip(zipCode, retryCount = 3) {
  const texasRegex = /7([5-9]\d{3}|33\d{2})|885\d{2}/;
  const zipcodeEndpoint = 'https://api.saveonenergy.cloud/zipcode';

  return fetch(`${zipcodeEndpoint}/${zipCode}`, {
    method: 'GET',
  }).then((results) => results.json()).then(
    (data) => data.state
  ).catch(() => {
    if (retryCount === 1) {
      if (texasRegex.test(zipCode)) return 'TX';
      return null;
    }

    return getStateByZip(zipCode, retryCount - 1);
  });
}

module.exports = {
  DefaultFilters,
  GridSortOptions,
  GridSortOptionsEP,
  TxuGridSortOptions,
  GridNewSOESortOptions,
  termTextToType,
  termTypeToText,
  FeaturedCardLabels,
  UsageAmounts,
  RatingSites,
  chameleonWidgetData,
  permaleasePivotPhoneNumbers,

  ProcessErrors,
  SupplierIds,
  ParentPartnerIds,
  UtilityMap,
  UtilityNames,

  Sites,
  Urls,
  cartUrl,
  cartV3Url,
  gridUrl,
  siteLegal,
  getStateByZip,
  siteCta,

  EPQ_DATA,
  assembleEPInput,
  supplierIdMap
};
